import { createSelector } from "@reduxjs/toolkit";

import { LearnerDiscussionStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectDiscussionState = (state: StateType): LearnerDiscussionStateType =>
  state.learnerDiscussionReducer;

export const selectDiscussions = createSelector(
  selectDiscussionState,
  (state: LearnerDiscussionStateType) => state.discussions
);

export const selectDiscussion = createSelector(
  selectDiscussionState,
  (state: LearnerDiscussionStateType) => state.discussion
);

export const selectComments = createSelector(
  selectDiscussionState,
  (state: LearnerDiscussionStateType) => state.discussionComments
);

export const selectDiscussionLoading = createSelector(
  selectDiscussionState,
  (state: LearnerDiscussionStateType) => state.loading
);

export const selectDiscussionLoaded = createSelector(
  selectDiscussionState,
  (state: LearnerDiscussionStateType) => state.loaded
);

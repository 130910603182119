const course = "COURSE";
const module = "MODULE";
const alert = "ALERT";
const quizzie = "QUIZZIE";
const assignment = "ASSIGNMENT";
const discussion = "DISCUSSION";

const newsFeedType = {
  course,
  module,
  alert,
  quizzie,
  assignment,
  discussion,
};

export { newsFeedType, course, module, alert, quizzie, assignment, discussion };

import { Typography, Skeleton, Input } from "antd";
import { useEffect, useState } from "react";
import EmptyState from "../../../shared/EmptyState";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import AlertCard from "./components/card";
import { newsFeedType } from "../../../../utils/constants/newsFeedTypes";

const { Text } = Typography;

const AlertsScreen = (props: any) => {
  const {
    alerts,
    alertsNewsFeed,
    loading,
    loaded,
    actions: { getAlert, getLearnerNewsFeed, deleteNewsFeed },
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAlerts, setFilteredAlerts] = useState(alerts);

  useEffect(() => {
    if (loaded) return;
    getAlert();
  }, [getAlert]);

  useEffect(() => {
    getLearnerNewsFeed();
  }, []);

  useEffect(() => {
    if (alertsNewsFeed && alertsNewsFeed > 0) {
      deleteNewsFeed({ type: newsFeedType.alert });
    }
  }, [alertsNewsFeed]);

  useEffect(() => {
    if (searchTerm) {
      let filtered = alerts.filter((alert: any) =>
        alert.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredAlerts(filtered);
    } else {
      setFilteredAlerts(alerts);
    }
  }, [searchTerm, alerts]);

  if (loading) return <Skeleton active />;

  return (
    <div className="h-full">
      {alerts && alerts.length > 0 ? (
        <>
          <div className="flex flex-col gap-3 mb-7">
            <Input
              placeholder="Search"
              prefix={<SearchIcon className="mx-3" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-1/2"
            />
          </div>

          {filteredAlerts && filteredAlerts.length > 0 ? (
            <div className="grid grid-cols-3 gap-x-8 gap-y-6 overflow-y-scroll max-h-[calc(100vh-255px)]">
              {filteredAlerts.map((alert: any) => (
                <AlertCard key={alert.id} alert={alert} />
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <EmptyState />
            </div>
          )}
        </>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </div>
  );
};

export default AlertsScreen;

import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  setCourse,
  setCourseById,
  getCourseById,
  getCourse,
  courseTypes as types,
} from "../actions";
import { AxiosResponse } from "axios";
import { resetStepper } from "../../shared/actions";
import {
  addCourseService,
  getCourseService,
  getCourseByIdService,
  assignLessonsService,
  deleteCourseByIdService,
  editCourseService,
  changePublishStatusCourseService,
  updateLessonOrderService
} from "../../../services/educator/course";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* educatorCourseSagas() {
  yield all([
    takeLatest(types.ADD_COURSE, handleAddCourse),
    takeLatest(types.GET_COURSE, handleGetCourse),
    takeLatest(types.GET_COURSE_BY_ID, handleGetCourseById),
    takeLatest(types.DELETE_COURSE, handleDeleteCourse),
    takeLatest(types.EDIT_COURSE, handleEditCourse),
    takeLatest(types.ASSIGN_LESSON, handleAssignLessons),
    takeLatest(types.CHANGE_PUBLISH_STATUS, handleChangePublishStatus),
    takeLatest(types.UPDATE_LESSON_ORDER, handleUpdateLessonOrder),
  ]);
}

function* handleAddCourse(action: any) {
  const { data }: AxiosResponse = yield call(
    addCourseService,
    action.payload.course
  );
  if (data) {
    yield put(getCourse());
    action.payload.navigate(`/educator/course/${data.id}/assign-lessons`);
  }
}

function* handleGetCourse() {
  const { data }: AxiosResponse = yield call(getCourseService);
  if (data) {
    yield put(setCourse({ courses: data }));
  }
}

function* handleGetCourseById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getCourseByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setCourseById({ course: data }));
  }
}

function* handleDeleteCourse(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteCourseByIdService,
    action.payload.id
  );
  if (data) {
    showNotification("success", "Course deleted successfully!");
    action.payload.navigate("/educator/course");
  }
}

function* handleAssignLessons(action: any) {
  const { data }: AxiosResponse = yield call(
    assignLessonsService,
    action.payload.courseId,
    action.payload.lessonIds
  );
  if (data) {
    yield put(getCourse());
    action.payload.navigate("/success/course", {
      state: { isEdit: action.payload.isEdit },
    });
  }
}

function* handleEditCourse(action: any) {
  const { data }: AxiosResponse = yield call(
    editCourseService,
    action.payload.id,
    action.payload.course
  );
  if (data) {
    showNotification("success", "Course edited successfully!");
    yield put(getCourse());
    if (action.payload.navigateRoute === "assign-lessons") {
      const { data: courseData }: AxiosResponse = yield call(
        getCourseByIdService,
        action.payload.id
      );
      action.payload.navigate(
        `/educator/course/${action.payload.id}/assign-lessons`,
        {
          state: { isEdit: true, course: courseData },
        }
      );
    } else {
      yield put(resetStepper());
      action.payload.navigate("/educator/course");
    }
  }
}

function* handleChangePublishStatus(action: any) {
  const { data }: AxiosResponse = yield call(
    changePublishStatusCourseService,
    action.payload.id,
    action.payload.publish
  );
  if (data) {
    yield put(getCourse());
  }

  if (data && action.payload.publish) {
    action.payload.navigate("/success/publish");
  } else {
    yield put(getCourseById({ id: action.payload.id }));
  }
}

function* handleUpdateLessonOrder(action: any) {
  const { data }: AxiosResponse = yield call(
    updateLessonOrderService,
    action.payload.id,
    action.payload.lessonIds
  );
  if (data) {
    showNotification("success", "Lessons order updated successfully!");
    yield put(getCourse());
  }
}
import { Card, Button, Typography, Image } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import emptyState from "../../utils/constants/emptyState";

const { Text } = Typography;

const EmptyState = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [Icon, setIcon] = useState<React.ComponentType<
    React.SVGProps<SVGSVGElement>
  > | null>(null);
  const [heading, setHeading] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [buttonLink, setButtonLink] = useState("/");

  useEffect(() => {
    const route = emptyState.find((r) =>
      location.pathname.endsWith(r.path)
    ) || {
      icon: null,
      heading: "",
      buttonText: "",
      buttonLink: "/",
    };
    setIcon(route.icon as any);
    setHeading(route.heading);
    if (route.buttonText) setButtonText(route.buttonText);
    if (route.buttonLink) setButtonLink(route.buttonLink);
  }, [location.pathname, emptyState]);

  return (
    <Card
      className={`w-[428px] ${
        buttonText && buttonLink && "h-[381px]"
      }  shadow-md p-[40px]`}
    >
      <div className="flex flex-col gap-10 items-center">
        <div className="p-4 h-[115px]">
          {Icon && (
            <Icon className="w-full h-20 text-[80px] text-primary-600 fill-primary-600" />
          )}
        </div>

        <Text className="text-neutral-600 text-xl font-bold text-center">
          {heading}
        </Text>
        {buttonText && buttonLink && (
          <Button
            className="w-full bg-primary-600 h-12 text-base font-semibold text-white"
            onClick={() => navigate(buttonLink)}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </Card>
  );
};

export default EmptyState;

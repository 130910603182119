import { useNavigate } from "react-router-dom";
import { Button, Image, Typography } from "antd";

import { assignmentProgress } from "../../../../utils/helpers/calculateAssignmentProgress";
import arrowRight from "../../../../assets/images/arrow-right-circle.svg";
import CircleProgress from "../../../shared/CircleProgress";

const { Text } = Typography;

const SingleAssignmentCard = ({ assignment, isActive }: any) => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-4">
      <div>
        <CircleProgress size={70} percentage={assignmentProgress(assignment)} />
      </div>
      <div
        className={`flex flex-col gap-6 ${
          isActive ? "w-[calc(100%-135px)]" : "w-[calc(100%-95px)]"
        } `}
      >
        <Text className={`text-neutral-600 text-sm`}>{assignment.title}</Text>
        {!isActive && (
          <>
            <div className="border-b border-neutral-200"></div>

            <div className=" flex justify-between gap-4">
              <Button
                onClick={() =>
                  navigate(`/educator/assignment/${assignment?.id}`)
                }
                type="primary"
                className="w-1/2 bg-white text-primary-600 border-primary-200 rounded-lg h-[32px] text-sm shadow-none"
              >
                Details
              </Button>
              <Button
                onClick={() =>
                  navigate(`/educator/assignment/${assignment?.id}/grade`)
                }
                type="primary"
                className="w-1/2 bg-primary-600 rounded-lg h-[32px] text-sm shadow-none"
              >
                Grade
              </Button>
            </div>
          </>
        )}
      </div>
      {isActive && (
        <div className="size-7">
          <Image preview={false} src={arrowRight} />
        </div>
      )}
    </div>
  );
};

export default SingleAssignmentCard;

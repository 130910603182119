import { createSelector } from "reselect";
import { StateType } from "../../../utils/types";

const selectLearnerAnalyticsState = (
  state: StateType
): { learnerAnalytics: any } => state.learnerAnalyticsReducer;

export const selectLearnerAnalytics = createSelector(
  selectLearnerAnalyticsState,
  (state: { learnerAnalytics: any }) => state.learnerAnalytics
);

export const selectLearnerAnalyticsLoading = createSelector(
  selectLearnerAnalyticsState,
  (state: any) => state.loading
);

export const selectLearnerAnalyticsLoaded = createSelector(
  selectLearnerAnalyticsState,
  (state: any) => state.loaded
);

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Input, Skeleton, Card } from "antd";

import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import FilterButtons from "../../../shared/FilterButtons";
import EmptyState from "../../../shared/EmptyState";
import { newsFeedType } from "../../../../utils/constants/newsFeedTypes";

const { Text } = Typography;

const LearnerAssignmentScreen = (props: any) => {
  const {
    assignments,
    assignmentsNewsFeed,
    loading,
    loaded,
    actions: { getAssignments, getLearnerNewsFeed, deleteNewsFeed },
  } = props;

  const [filteredAssignments, setFilteredAssignments] = useState(assignments);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("ALL");
  const filterProps = [
    { key: "All Assignments", value: "ALL" },
    { key: "Submitted", value: "SUBMITTED" },
    { key: "Graded", value: "GRADED" },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) return;
    getAssignments();
  }, []);

  useEffect(() => {
    getLearnerNewsFeed();
  }, []);

  useEffect(() => {
    if (assignmentsNewsFeed && assignmentsNewsFeed > 0) {
      deleteNewsFeed({ type: newsFeedType.assignment });
    }
  }, [assignmentsNewsFeed]);

  useEffect(() => {
    let filtered = assignments;

    if (filter === "SUBMITTED") {
      filtered = filtered.filter(
        (assignment: any) => assignment?.submissions[0]?.grade === null
      );
    } else if (filter === "GRADED") {
      filtered = filtered.filter(
        (assignment: any) =>
          assignment?.submissions?.length > 0 &&
          assignment?.submissions[0]?.grade !== null
      );
    }

    if (searchTerm) {
      filtered = filtered.filter((module: any) =>
        module.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredAssignments(filtered);
  }, [searchTerm, filter, assignments]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  if (loading) return <Skeleton active />;

  return (
    <>
      {assignments && assignments.length > 0 ? (
        <div>
          <div>
            <Input
              placeholder="Search"
              className="w-1/2"
              prefix={<SearchIcon className="mx-3" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <FilterButtons
            selectedFilter={filter}
            handleFilterChange={handleFilterChange}
            filterOptions={filterProps}
          />
          <div className="flex flex-wrap gap-x-10 gap-y-4 mt-4 overflow-y-scroll max-h-[calc(100vh-290px)]">
            {filteredAssignments?.length > 0 ? (
              filteredAssignments.map((assignment: any, index: number) => (
                <Card
                  key={index}
                  className="w-[calc(50%-20px)] p-10 border-none shadow-sm rounded-lg"
                >
                  <div className="flex flex-col">
                    <Text className="text-neutral-600 font-semibold text-sm h-10 line-clamp-2">
                      {assignment?.title}
                    </Text>
                    <div className="border-b border-neutral-200 mb-4 mt-2"></div>
                    <Button
                      onClick={() =>
                        navigate(`/user/assignment/${assignment.id}`)
                      }
                      type="primary"
                      className={`xl:w-1/2 text-sm font-semibold ${
                        assignment?.submissions?.length === 0 ||
                        assignment?.submissions[0]?.grade === null
                          ? "bg-white text-primary-600 border border-primary-200"
                          : "bg-success-550 text-white "
                      }`}
                    >
                      {assignment?.submissions?.length === 0
                        ? "Start"
                        : assignment?.submissions[0]?.grade === null
                        ? "View Submission"
                        : "View Grade"}
                    </Button>
                  </div>
                </Card>
              ))
            ) : (
              <div className="flex items-center w-full justify-center h-full py-[60px]">
                <EmptyState />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default LearnerAssignmentScreen;

import { Card, Image, Typography, Button } from "antd";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import entityCard from "../../utils/constants/entityCard";
import { ReactComponent as DurationIcon } from "../../assets/images/reusable-items/duration.svg";
import testImage from "../../assets/images/default-cover-image.jpg";
import { ReactComponent as LessonIcon } from "../../assets/images/reusable-items/lesson.svg";
import { ReactComponent as CourseIcon } from "../../assets/images/reusable-items/course.svg";

const { Text } = Typography;
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const EntityCard = (props: any) => {
  const { title, coverImageKey, numberOfEntities, duration, footer, classes } =
    props;
  const [entities, setEntities] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [widthClass, setWidthClass] = useState("");
  const [heightClass, setHeightClass] = useState("");

  const location = useLocation();

  useEffect(() => {
    const route = entityCard.find((r) =>
      location.pathname.includes(r.path)
    ) || {
      entities: "",
      icon: null,
      coverImageKey: "",
      widthClass: "w-[500px]",
      heightClass: "h-[180px]",
    };
    setEntities(route.entities);
    setImageSrc(`${cloudfrontKey}/${coverImageKey}`);
    setWidthClass(route.widthClass);
    setHeightClass(route.heightClass);
    if (coverImageKey === "") setImageSrc(testImage);
  }, [location.pathname]);

  return (
    <Card
      className={`shadow-md p-5 ${classes ?? widthClass} ${
        classes ?? heightClass
      }`}
    >
      <div className="flex w-full h-full gap-8">
        <div className="flex-1">
          <Image
            src={imageSrc}
            preview={false}
            height={248}
            className="rounded-lg object-cover w-[179px] "
          />
        </div>

        <div className="flex-1 flex flex-col justify-between gap-3">
          <Text className="text-base text-neutral-600 font-semibold line-clamp-3">
            {title}
          </Text>
          <div>
            <div className="flex-wrap flex justify-between border-b border-b-neutral-200 mb-4 pb-3">
              {(numberOfEntities || numberOfEntities === 0) && (
                <div className=" flex gap-2 items-center">
                  {entities === "Courss" ? (
                    <CourseIcon className="w-4 h-4 fill-primary-400" />
                  ) : entities === "Lessons" ? (
                    <LessonIcon className="w-4 h-4 fill-primary-400" />
                  ) : null}
                  <Text className="text-sm text-neutral-500">
                    {numberOfEntities} {entities}
                  </Text>
                </div>
              )}
              <div className="flex items-center gap-2">
                <div className="w-4 h-4">
                  <DurationIcon className="fill-primary-400" />
                </div>

                <Text className="text-sm text-neutral-500 ">{duration}</Text>
              </div>
            </div>
            <div>{footer}</div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EntityCard;

import { useEffect, useState } from "react";
import { Button, Checkbox, Modal, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

import { selectUsers } from "../../redux/educator/selector";
import { userTypes as types } from "../../redux/educator/actions";

const { Text } = Typography;

const EducatorSelector = ({
  onSelectionChange,
  selectedEducators,
  setSelectedEducators,
}: any) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tempSelectedEducators, setTempSelectedEducators] = useState<string[]>(
    []
  );

  const educatorOptions = useSelector(selectUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: types.GET_USER_BY_ROLE, payload: "educator" });
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
    setSelectedEducators(tempSelectedEducators);
    if (onSelectionChange) {
      onSelectionChange(tempSelectedEducators);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTempSelectedEducators(selectedEducators);
  };

  const handleSelect = (educator: any) => {
    setTempSelectedEducators((prevSelected: string[]) =>
      prevSelected.includes(educator)
        ? prevSelected.filter((item) => item !== educator)
        : [...prevSelected, educator]
    );
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allEducatorIds = educatorOptions.map(
        (educator: any) => educator.id
      );
      setTempSelectedEducators(allEducatorIds);
    } else {
      setTempSelectedEducators([]);
    }
  };

  const openModal = () => {
    setTempSelectedEducators(selectedEducators);
    setIsModalVisible(true);
  };

  return (
    <>
      <div className="w-full bg-neutral-100 pl-4 rounded-lg shadow-md mt-2 mb-6">
        <div className="flex justify-between items-center h-[50px]">
          <div>
            <Text
              className={`text-neutral-400 text-sm font-light ${
                selectedEducators?.length > 0 ? "" : "font-semibold"
              }`}
            >
              {selectedEducators?.length > 0
                ? `Selected Educators (${selectedEducators?.length})`
                : "Select from a list"}
            </Text>
          </div>
          <Button
            onClick={openModal}
            className="border border-neutral-200 text-neutral-600 text-base font-semibold shadow-none h-full"
          >
            {selectedEducators?.length === 0 ? "Select" : "Add More"}
          </Button>
        </div>
        {selectedEducators.length > 0 && (
          <div className="flex flex-col pt-4 mt-2 gap-1">
            {selectedEducators.map((educatorId: string) => {
              const educator = educatorOptions.find(
                (ed: any) => ed.id === educatorId
              );
              return (
                <div
                  key={educatorId}
                  className="flex items-center px-4 py-3 rounded-lg bg-neutral-100"
                >
                  <Text className="text-neutral-600 text-sm">
                    {educator?.name ?? educator?.email}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
      >
        <div className="flex justify-between items-center pb-4 mb-4 border-b border-b-neutral-200">
          <div className="">
            <Text className="text-neutral-400 font-normal text-sm">
              Educator(s)
            </Text>
          </div>
          <Checkbox
            onChange={(e) => handleSelectAll(e.target.checked)}
            checked={tempSelectedEducators.length === educatorOptions.length}
          >
            <Text className="text-neutral-400 font-normal text-sm">
              Select All
            </Text>
          </Checkbox>
        </div>
        <div className="flex flex-col max-h-[calc(100vh-300px)] overflow-y-auto">
          {educatorOptions &&
            educatorOptions.map((educator: any, index: any) => (
              <div
                key={index}
                onClick={() => handleSelect(educator.id)}
                className={`flex items-center px-4 py-3 mb-2 rounded-lg cursor-pointer ${
                  tempSelectedEducators.includes(educator.id)
                    ? "bg-primary-100"
                    : "bg-neutral-100"
                } hover:bg-primary-200 transition`}
              >
                <Text
                  className={`flex-1 text-neutral-600 text-sm ${
                    tempSelectedEducators.includes(educator.id)
                      ? "font-semibold"
                      : ""
                  }`}
                >
                  {educator.name ?? educator.email}
                </Text>
                {tempSelectedEducators.includes(educator.id) && (
                  <CheckOutlined className="text-blue-500" />
                )}
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default EducatorSelector;

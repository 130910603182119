import React from "react";

const LikeIcon = ({ isLiked }: { isLiked: boolean | undefined }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_1474_21164)">
        <path
          d="M3.19238 10.4375C3.19238 9.71263 3.78001 9.125 4.50488 9.125C5.22976 9.125 5.81738 9.71263 5.81738 10.4375V15.6875C5.81738 16.4124 5.22976 17 4.50488 17C3.78001 17 3.19238 16.4124 3.19238 15.6875V10.4375Z"
          fill={`${!isLiked ? "#C7D2FE" : "#8199F8"}`}
        />
        <path
          d="M6.69238 10.2917V15.0434C6.69238 15.7063 7.06689 16.3123 7.65976 16.6087L7.70337 16.6305C8.18937 16.8735 8.72526 17 9.26862 17H14.0077C14.8419 17 15.5601 16.4112 15.7237 15.5932L16.7737 10.3432C16.9903 9.26032 16.1621 8.25 15.0577 8.25H11.9424V4.75C11.9424 3.7835 11.1589 3 10.1924 3C9.70913 3 9.31738 3.39175 9.31738 3.875V4.45833C9.31738 5.21563 9.07176 5.9525 8.61738 6.55833L7.39238 8.19167C6.93801 8.7975 6.69238 9.53437 6.69238 10.2917Z"
          fill={`${!isLiked ? "#C7D2FE" : "#8199F8"}`}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_1474_21164"
          x="3.19238"
          y="3"
          width="13.6162"
          height="16"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1474_21164"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LikeIcon;

import { Card, Typography, Avatar } from "antd";
import { ReactComponent as AlertIcon } from "../../../../../assets/images/reusable-items/alert.svg";
import { UserOutlined } from "@ant-design/icons";
import { formatTimeAgo } from "../../../../../utils/helpers";

const { Text } = Typography;

const AlertCard = ({ alert }: { alert: any }) => {
  return (
    <Card key={alert.id} className="p-4">
      <div className="flex flex-col h-full">
        <div className="flex gap-4 mb-4 flex-1">
          <div className="w-6 h-6">
            <AlertIcon className="w-6 fill-primary-600" />
          </div>

          <Text className="text-neutral-600 font-semibold text-sm">
            {alert.title}
          </Text>
        </div>

        <div className="bg-primary-100 py-2 px-4 flex items-center rounded-lg">
          <div className="flex-1">
            <Text className="text-primary-600 text-xs">Created by</Text>

            <div className="flex items-center gap-2 mt-1">
              <Avatar
                size={20}
                className="min-w-5"
                src={
                  alert.user && alert.user.profilePictureKey
                    ? `${process.env.REACT_APP_CLOUDFRONT_KEY}/${alert.user.profilePictureKey}`
                    : false
                }
                icon={<UserOutlined />}
              />
              <Text className="text-neutral-600 font-semibold text-sm line-clamp-3">
                {alert?.user?.name || alert?.user?.email || "Unknown"}
              </Text>
            </div>
          </div>
          <div className="text-neutral-500 text-xs font-medium">
            {formatTimeAgo(alert.createdAt)}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AlertCard;

import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import {
  deleteNewsFeed,
  getLearnerNewsFeed,
  getQuizzie,
} from "../../../../redux/learner/actions";
import {
  selectLearnerNewsFeed,
  selectQuizzies,
  selectQuizziesLoaded,
  selectQuizziesLoading,
} from "../../../../redux/learner/selector";
import { StateType } from "../../../../utils";

const mapStateToProps = (state: StateType) => ({
  quizzies: selectQuizzies(state),
  quizziesNewsFeed: selectLearnerNewsFeed(state)?.quizzies,
  loading: selectQuizziesLoading(state),
  loaded: selectQuizziesLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getQuizzie,
      getLearnerNewsFeed,
      deleteNewsFeed,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);

import { Typography } from "antd";
import { ReactComponent as UserIcon } from "../../../../assets/images/user.svg";

const { Text } = Typography;

const AssignmentUser = ({ user }: any) => {
  return (
    <div className="w-full h-[50px] bg-neutral-100 border border-neutral-200 rounded-lg flex items-center gap-4 px-6 py-4 mb-1">
      <UserIcon className="min-w-5" />
      <Text className="text-neutral-600 font-semibold text-base leading-4 line-clamp-2">
        {user?.name ?? user?.email}
      </Text>
    </div>
  );
};

export default AssignmentUser;

import { Card, Image, Typography, Button, Progress } from "antd";
const { Text } = Typography;

const CardProgress = ({
  classes,
  title,
  progress,
  footerButton,
  isFinished,
  size = "default",
}: any) => (
  <Card className={`shadow-md p-5 ${classes} `}>
    <div className="flex w-full h-full gap-5">
      <div className="flex items-center">
        <Progress
          type="circle"
          strokeWidth={9}
          strokeColor={{ "0%": "#FDA4AF", "100%": "#5063EE" }}
          percent={progress}
          size={size}
        />
      </div>

      <div className="flex flex-col justify-between gap-3">
        <Text className="text-base text-neutral-600 font-semibold line-clamp-2">
          {title}
        </Text>
        <div>
          <div>
            <div className="flex flex-row items-center gap-3">
              <div
                className={`${
                  isFinished
                    ? "bg-primary-100 rounded-lg  text-primary-600 border-primary-200"
                    : "bg-white border-primary-500 text-primary-600"
                } p-[4px] border-2 rounded-lg flex flex-row flex-wrap items-baseline justify-center text-xs`}
              >
                <span className="text-sm font-semibold">
                  {isFinished ? "Finished" : "In Progress"}
                </span>
              </div>
              {footerButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

export default CardProgress;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Skeleton } from "antd";
import { ConnectedProps } from "react-redux";

import CircleProgress from "../../../shared/CircleProgress";
import connector from "./Container";

const HomeAnalytics = (props: ConnectedProps<typeof connector>) => {
  const {
    analytics,
    loading,
    loaded,
    actions: { getLearnerAnalytics },
  } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) return;
    getLearnerAnalytics();
  }, []);

  if (loading) {
    return (
      <div className="h-[300px]">
        <Skeleton active paragraph={{ rows: 7 }} />
      </div>
    );
  }
  return (
    <>
      {analytics && (
        <>
          <div className=" flex justify-between">
            <div className="flex flex-col justify-between">
              <div className="text-neutral-600 font-semibold text-base">
                Overall Progress
              </div>
              <div className="text-primary-600 text-[12px]">
                {analytics.progress}% Complete
              </div>
            </div>
            <div className="flex flex-col items-center justify-between">
              <div className="text-primary-600 font-bold text-2xl">
                {analytics?.points}
              </div>
              <div className="text-neutral-600 text-xs">PTS</div>
            </div>
          </div>
          <div className="border-b-[1.2px]  border-neutral-200 my-5"></div>
          <div className="flex justify-center mb-6">
            <CircleProgress size={140} percentage={analytics.progress} />
          </div>

          <Button
            onClick={() => navigate("/user/dashboard")}
            className="w-full h-[46px] bg-primary-600 text-white font-semibold rounded-lg"
          >
            View Details
          </Button>
        </>
      )}
    </>
  );
};

export default HomeAnalytics;

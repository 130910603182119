import { produce } from "immer";

import { moduleTypes as types } from "../actions";
import { EducatorModuleStateType } from "../../../utils";

const defaultState: EducatorModuleStateType = {
  modules: [],
  module: null,
  loading: false,
  loaded: false,
};

const handleSetModule = (state: any, payload: any) => {
  state.module = payload.module;
  state.loading = false;
  return state;
};

const handleSetModules = (state: any, payload: any) => {
  state.modules = payload.modules;
  state.loading = false;
  state.loaded = true;
  return state;
};

export default produce(
  (state: EducatorModuleStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_MODULE:
        return handleSetModules(state, action.payload);
      case types.SET_MODULE_BY_ID:
        return handleSetModule(state, action.payload);
      case types.GET_MODULE_BY_ID:
        return {
          ...state,
          loading: true,
        };
      case types.GET_MODULE:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);

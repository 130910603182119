import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  deleteLesson,
  getLessonById,
} from "../../../../redux/educator/actions";
import { StateType } from "../../../../utils/types";
import {
  selectLesson,
  selectLessonLoading,
} from "../../../../redux/educator/selector";
import { selectLessonPresignedUrl } from "../../../../redux/learner/selector";
import {
  getPresignedUrl,
  removePresignedUrl,
} from "../../../../redux/learner/actions";

const mapStateToProps = (state: StateType) => ({
  lesson: selectLesson(state),
  presignedUrl: selectLessonPresignedUrl(state),
  loading: selectLessonLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getLessonById,
      deleteLesson,
      getPresignedUrl,
      removePresignedUrl,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);

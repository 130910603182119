import { produce } from "immer";

import { discussionTypes as types } from "../actions";
import { LearnerDiscussionStateType } from "../../../utils";

const defaultState: LearnerDiscussionStateType = {
  discussions: [],
  discussion: null,
  discussionComments: [],
  loaded: false,
  loading: false,
};

const handleSetDiscussions = (
  state: LearnerDiscussionStateType,
  payload: any
) => {
  state.discussions = payload.discussions;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetDiscussion = (
  state: LearnerDiscussionStateType,
  payload: any
) => {
  state.discussion = payload.discussion;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetComments = (state: LearnerDiscussionStateType, payload: any) => {
  state.discussionComments = payload.comments;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleUpdateComments = (state: any, payload: any) => {
  state.discussionComments.push(payload.comment);
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetEditedComment = (state: any, { comment }: any) => {
  state.discussionComments = state.discussionComments.map((discussion: any) => {
    if (discussion.id === comment.id) {
      return comment;
    }
    return discussion;
  });
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetDeletedComment = (state: any, { comment }: any) => {
  state.discussionComments = state.discussionComments.filter(
    (discussion: any) => discussion.id !== comment.commentId
  );
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetDeletedDiscussion = (state: any, { id }: any) => {
  state.discussions = state.discussions.filter(
    (discussion: any) => discussion.id !== id
  );
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetChangeResolvedDiscussion = (state: any, { id }: any) => {
  state.discussions = state.discussions.map((discussion: any) => {
    if (discussion.id === id) {
      return { ...discussion, isResolved: !discussion.isResolved };
    }
    return discussion;
  });
  state.loading = false;
  state.loaded = true;
  return state;
};

export default produce(
  (state: LearnerDiscussionStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_DISCUSSIONS:
        return handleSetDiscussions(state, action.payload);
      case types.SET_DISCUSSION_BY_ID:
        return handleSetDiscussion(state, action.payload);
      case types.SET_COMMENTS:
        return handleSetComments(state, action.payload);
      case types.UPDATE_COMMENTS:
        return handleUpdateComments(state, action.payload);
      case types.SET_EDITED_COMMENT:
        return handleSetEditedComment(state, action.payload);
      case types.SET_DELETED_COMMENT:
        return handleSetDeletedComment(state, action.payload);
      case types.SET_DELETED_DISCUSSION:
        return handleSetDeletedDiscussion(state, action.payload);
      case types.SET_CHANGE_RESOLVED_STATUS:
        return handleSetChangeResolvedDiscussion(state, action.payload);
      case types.GET_DISCUSSIONS:
        return {
          ...state,
          loading: true,
        };
      case types.GET_DISCUSSION_BY_ID:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);

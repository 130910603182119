import { Button, Col, Typography, Skeleton } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EntityCard from "../../../shared/EntityCard";
import AddEntity from "../../../shared/AddEntity";
import { ReactComponent as LessonIcon } from "../../../../assets/images/add-entity-lesson.svg";
import { LessonProps } from "../../../../utils/types";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";

const { Text } = Typography;

const AssignLessonsScreen = (props: any) => {
  const {
    lessons,
    loading,
    actions: { setNextStep, assignLesson, getLesson },
  } = props;

  const [selectedLessons, setSelectedLessons] = useState<string[]>([]);
  const [initialSelectedLessons, setInitialSelectedLessons] = useState<
    string[]
  >([]);
  const [showLessons, setShowLessons] = useState(false);
  const { id: courseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isEdit = false, course = {} } = location.state || {};

  const toggleSelect = (lessonId: string) => {
    setSelectedLessons((prevSelected: string[]) =>
      prevSelected.includes(lessonId)
        ? prevSelected.filter((id: string) => id !== lessonId)
        : [...prevSelected, lessonId]
    );
  };

  useEffect(() => {
    setNextStep();
    getLesson();
    if (isEdit && course) {
      setShowLessons(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEdit && course && lessons) {
      const initialLessons = course?.lessons.map((lesson: any) => lesson.id);
      setSelectedLessons(initialLessons);
      setInitialSelectedLessons(initialLessons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessons]);

  const handleAddSelectedLessons = () => {
    if (areLessonsSame()) {
      navigate(`/educator/course/${courseId}`);
      return;
    }

    assignLesson({ courseId, lessonIds: selectedLessons, navigate, isEdit });
  };

  const areLessonsSame = () => {
    if (selectedLessons.length !== initialSelectedLessons.length) {
      return false;
    }
    const selectedSet = new Set(selectedLessons);
    const initialSet = new Set(initialSelectedLessons);
    let areSame = true;
    selectedSet.forEach((lessonId) => {
      if (!initialSet.has(lessonId)) {
        areSame = false;
      }
    });
    return areSame;
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {!showLessons ? (
        <div className="flex pb-12">
          <Col lg={8}>
            <CreateEntityInfo
              title=""
              text={`You can add existing lessons to the New Course you are creating or create new lessons to be part of this Course.`}
            />
          </Col>
          <Col lg={16} className="max-w-[600px]">
            <Text className="text-neutral-600 font-semibold text-lg ml-2">
              Add Lessons to Course
            </Text>
            <div className="h-full w-full flex items-center justify-center mt-2">
              <AddEntity
                Icon={LessonIcon}
                firstButtonText="Select Existing Lesson/s"
                firstButtonOnClick={() => setShowLessons(true)}
                secondButtonText="Create New"
                secondButtonOnClick={() => navigate("/educator/lesson/create")}
              />
            </div>
          </Col>
        </div>
      ) : (
        <div>
          <Text className="text-neutral-600 text-lg font-semibold">
            Add Lessons to Course
          </Text>

          <div className="flex flex-wrap gap-4 mt-6 overflow-y-auto max-h-[calc(100vh-300px)]">
            {lessons ? (
              lessons.map((lesson: LessonProps) => (
                <EntityCard
                  key={lesson.id}
                  title={lesson.title}
                  duration={lesson.duration || "0 hr 0 min"}
                  coverImageKey={lesson.coverImageKey || ""}
                  classes="w-[49%] max-w-[584px] h-[211px]"
                  footer={
                    <div>
                      {selectedLessons.includes(lesson.id) ? (
                        <Button
                          type="primary"
                          className="w-full h-8 bg-rose-400 hover:bg-rose-200"
                          onClick={() => toggleSelect(lesson.id)}
                          key="select"
                        >
                          Selected
                        </Button>
                      ) : (
                        <div className="flex gap-2">
                          <div className="bg-rose-400 text-white text-base font-semibold h-8 leading-8 rounded-lg px-4 ">
                            {lesson.points}
                            <span className="text-xs font-normal"> PTS</span>
                          </div>
                          <Button
                            type="primary"
                            className="w-full h-8 flex-1"
                            onClick={() => toggleSelect(lesson.id)}
                            key="select"
                          >
                            Select
                          </Button>
                        </div>
                      )}
                    </div>
                  }
                />
              ))
            ) : (
              <Text className="text-neutral-600 text-base">
                No lessons available.
              </Text>
            )}
          </div>

          <div className="flex justify-end mt-6">
            <Button
              className=" bg-primary-600 font-semibold text-base h-[50px] text-white"
              onClick={handleAddSelectedLessons}
            >
              Add Selected Lessons
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AssignLessonsScreen;

import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  setQuiz,
  setQuizById,
  setGeneratedQuiz,
  quizTypes as types,
  getQuiz,
  quizSubmissionComplete,
} from "../actions/quiz";
import { EducatorAddQuizActionType, Quiz } from "../../../utils/types";
import { AxiosResponse } from "axios";
import { resetStepper } from "../../shared/actions";
import {
  addQuizService,
  deleteQuizByIdService,
  getQuizByIdService,
  getQuizService,
  editQuizService,
  assignQuizService,
  generateQuizService,
} from "../../../services/educator/quiz";
import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";

export default function* educatorQuizSagas() {
  yield all([
    takeLatest(types.ADD_QUIZ, handleAddQuiz),
    takeLatest(types.GET_QUIZ, handleGetQuiz),
    takeLatest(types.GET_QUIZ_BY_ID, handleGetQuizById),
    takeLatest(types.DELETE_QUIZ, handleDeleteQuiz),
    takeLatest(types.EDIT_QUIZ, handleEditQuiz),
    takeLatest(types.ASSIGN_QUIZ, handleAssignQuiz),
    takeLatest(types.GENERATE_QUIZ, handleGenerateQuiz),
  ]);
}

function* handleAddQuiz(action: EducatorAddQuizActionType) {
  const formData = generateQuizFormData(action.payload.quiz, false);
  const { data }: AxiosResponse = yield call(addQuizService, formData);

  if (data) {
    yield put(quizSubmissionComplete());
    action.payload.navigate("/success/quiz");
    yield put(resetStepper());
    yield put(getQuiz());
  }
}

function* handleGetQuiz() {
  const { data }: AxiosResponse = yield call(getQuizService);
  if (data) {
    yield put(setQuiz({ quizzes: data }));
  }
}

function* handleGetQuizById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getQuizByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setQuizById({ quiz: data }));
  }
}

function* handleDeleteQuiz(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    deleteQuizByIdService,
    action.payload.id
  );
  if (data) {
    showNotification("success", "Quiz deleted successfully!");
    yield put(getQuiz());
  }
}

function* handleEditQuiz(action: any) {
  const isEditFromLessonPage = !action.payload.navigate;

  const formData = generateQuizFormData(
    action.payload.quiz,
    true,
    action.payload.deletedImages,
    action.payload.existingImages,
    isEditFromLessonPage
  );
  const { data }: AxiosResponse = yield call(
    editQuizService,
    action.payload.id,
    formData
  );

  if (data && !isEditFromLessonPage) {
    action.payload.navigate("/success/quiz", {
      state: { isEdit: true },
    });
    yield put(getQuiz());
    yield put(resetStepper());
  }
}

function* handleAssignQuiz(action: any) {
  const { data }: AxiosResponse = yield call(
    assignQuizService,
    action.payload.lessonId,
    action.payload.quizId
  );
  if (data) {
    if (action.payload.navigate) {
      action.payload.navigate("/success/lesson");
      yield put(resetStepper());
    }
  }
}

function* handleGenerateQuiz(action: any) {
  const { data }: AxiosResponse = yield call(
    generateQuizService,
    action.payload.lessonId
  );
  if (!data) {
    showNotification("error", "Failed to generate quiz");
  }
  yield put(
    setGeneratedQuiz({ quiz: data, overwrite: action.payload.overwrite })
  );
}

const generateQuizFormData = (
  quiz: Quiz,
  isEdit: boolean,
  deletedImages: number[] = [],
  existingImages: any[] = [],
  isEditFromLessonPage?: boolean
) => {
  const formData = new FormData();
  formData.append("title", quiz.title);
  formData.append("isMandatory", quiz.isMandatory ? "true" : "false");
  formData.append(
    "minimumCorrectAnswers",
    quiz.minimumCorrectAnswers.toString()
  );
  isEditFromLessonPage && formData.append("lessonId", "null");

  quiz.questions.forEach((q, index) => {
    if (q?.id && typeof q?.id === "string" && q?.id.includes("-")) {
      formData.append(`questions[${index}][id]`, q.id);
    }
    formData.append(`questions[${index}][question]`, q.question);
    q.answers.forEach((a, i) => {
      if (a?.id && typeof a?.id === "string" && a?.id?.includes("-")) {
        formData.append(`questions[${index}][answers][${i}][id]`, a.id);
      }
      formData.append(`questions[${index}][answers][${i}][answer]`, a.answer);
      formData.append(
        `questions[${index}][answers][${i}][isCorrect]`,
        a.isCorrect ? "true" : "false"
      );
    });

    if (isEdit && deletedImages.includes(index)) {
      formData.append(`questions[${index}][imageDeleted]`, "true");
    } else if (q?.image?.file) {
      const extension = q?.image?.fileList[0]?.type.split("/")[1];
      const imageName = `q${index + 1}.${extension}`;
      const renamedFile = new File(
        [q?.image?.fileList[0].originFileObj],
        imageName,
        {
          type: q?.image?.fileList[0]?.type,
        }
      );

      formData.append("images", renamedFile);
    }

    if (
      isEdit &&
      existingImages.some((item: any) => item.questionIndex === index)
    ) {
      formData.append(
        `questions[${index}][imageKey]`,
        existingImages.find((item: any) => item.questionIndex === index)
          .imageKey
      );
    }
  });

  return formData;
};

import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  selectAssignment,
  selectAssignmentLoading,
  selectSubmissions,
  selectUserSubmission,
} from "../../../../redux/educator/selector";
import {
  getAssignmentById,
  getSubmissions,
  getUserSubmission,
  gradeSubmission,
} from "../../../../redux/educator/actions";
import { selectLessonPresignedUrl } from "../../../../redux/learner/selector";
import {
  getPresignedUrl,
  removePresignedUrl,
} from "../../../../redux/learner/actions";

const mapStateToProps = (state: StateType) => ({
  assignment: selectAssignment(state),
  submissions: selectSubmissions(state),
  userSubmission: selectUserSubmission(state),
  presignedUrl: selectLessonPresignedUrl(state),
  loading: selectAssignmentLoading(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getAssignmentById,
      getSubmissions,
      getUserSubmission,
      gradeSubmission,
      getPresignedUrl,
      removePresignedUrl,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);

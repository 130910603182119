import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConnectedProps, useSelector } from "react-redux";
import { Button, Typography, Row, Col, Avatar } from "antd";

import CompanyStructureItems from "../../../assets/images/company-structure-items/company-structure-items";
import testLogo from "../../../assets/images/company-structure-items/companyInfo.svg";
import CreateEntityInfo from "../../shared/CreateEntityInfo";
import { selectUser } from "../../../redux/auth/selector";
import connector from "./Container";
import CompanyInfoEdit from "./EditCompanyInfo";

const { Text } = Typography;
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const CompanyInfoScreen = (props: ConnectedProps<typeof connector>) => {
  const {
    companyInfo,
    actions: { getCompanyInfoById, editCompanyInfo, setCurrentStep },
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentStep({ steps: 4, currentStep: 1 });
    const id = user?.companyId;
    id && getCompanyInfoById({ id });
  }, [user]);

  return (
    <Row className="h-full pb-[60px]">
      <Col lg={8}>
        <CreateEntityInfo
          title={!isEditing ? "" : "Hello,"}
          text={
            <>
              <div className="mb-6">Here is the Company’s information.</div>
              <div>
                {isEditing
                  ? "You can edit the Company Name and Logo."
                  : "You can update the information at any time."}
              </div>
            </>
          }
        />
      </Col>
      <Col lg={16} className="gap-4 h-full flex flex-col max-w-[600px]">
        <div>
          {!isEditing ? (
            <div className="bg-white shadow-md rounded-lg p-5 flex flex-col">
              <div className="flex justify-between items-center mb-6">
                <div className="flex gap-4">
                  <CompanyStructureItems.Step1 />
                  <Text className="text-neutral-600 font-normal text-lg">
                    Company Info
                  </Text>
                </div>
                <Button
                  type="link"
                  onClick={() => setIsEditing(true)}
                  className="text-primary-600 font-semibold text-sm pr-0 pl-2"
                >
                  Edit
                </Button>
              </div>
              <Text className="text-neutral-600 font-semibold text-sm">
                Company Name
              </Text>
              <div className="h-[50px] bg-neutral-100 flex items-center px-4 text-neutral-600 font-semibold text-base rounded-lg mt-4 mb-6 overflow-auto">
                {companyInfo?.name}
              </div>
              <div className="h-[100px] flex justify-between">
                <Text className="text-neutral-600 font-semibold text-sm">
                  Company Logotype
                </Text>
                <Avatar
                  size={100}
                  className="shadow-md border border-neutral-200"
                  src={
                    companyInfo?.imageKey
                      ? `${cloudfrontKey}/${companyInfo?.imageKey}`
                      : testLogo
                  }
                ></Avatar>
              </div>
            </div>
          ) : (
            <CompanyInfoEdit
              companyInfo={companyInfo}
              setIsEditing={setIsEditing}
              editCompanyInfo={editCompanyInfo}
            />
          )}
        </div>

        {!isEditing && (
          <div className="flex w-full gap-10">
            <Button
              className="flex-1 text-primary-600 font-semibold text-sm border border-primary-200 h-[50px]"
              onClick={() => navigate("/educator/home")}
            >
              Save & Exit
            </Button>
            <Button
              className="flex-1 bg-primary-600 text-white font-semibold text-sm h-[50px]"
              onClick={() => navigate("/educator/department")}
            >
              Continue
            </Button>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default CompanyInfoScreen;

import { produce } from "immer";

import { assignmentTypes as types } from "../actions";
import { EducatorAssignmentStateType } from "../../../utils";

const defaultState: EducatorAssignmentStateType = {
  assignments: [],
  assignment: null,
  submissions: [],
  submission: null,
  assignmentComments: [],
  loaded: false,
  loading: false,
};

const handleSetAssignment = (
  state: EducatorAssignmentStateType,
  payload: any
) => {
  state.assignment = payload.assignment;
  state.loading = false;
  return state;
};

const handleSetAssignments = (
  state: EducatorAssignmentStateType,
  payload: any
) => {
  state.assignments = payload.assignments;
  state.loading = false;
  state.loaded = true;
  return state;
};

const handleSetSubmissions = (
  state: EducatorAssignmentStateType,
  payload: any
) => {
  state.submissions = payload.submissions;
  state.loading = false;
  return state;
};

const handleSetUserSubmission = (
  state: EducatorAssignmentStateType,
  payload: any
) => {
  state.submission = payload.submission;
  state.loading = false;
  return state;
};

const handleSetComments = (
  state: EducatorAssignmentStateType,
  payload: any
) => {
  state.assignmentComments = payload.comments;
  state.loading = false;
  return state;
};

const handleUpdateComments = (
  state: EducatorAssignmentStateType,
  payload: any
) => {
  state.assignmentComments.push(payload.comment);
  state.loading = false;
  return state;
};

const handleSetEditedComment = (
  state: EducatorAssignmentStateType,
  { comment }: any
) => {
  state.assignmentComments = state.assignmentComments.map((discussion: any) => {
    if (discussion.id === comment.id) {
      return comment;
    }
    return discussion;
  });
  state.loading = false;
  return state;
};

const handleSetDeletedComment = (
  state: EducatorAssignmentStateType,
  { commentId }: any
) => {
  state.assignmentComments = state.assignmentComments.filter(
    (discussion: any) => discussion.id !== commentId
  );
  state.loading = false;
  return state;
};

export default produce(
  (state: EducatorAssignmentStateType = defaultState, action) => {
    switch (action.type) {
      case types.SET_ASSIGNMENTS:
        return handleSetAssignments(state, action.payload);
      case types.SET_ASSIGNMENT_BY_ID:
        return handleSetAssignment(state, action.payload);
      case types.SET_SUBMISSIONS:
        return handleSetSubmissions(state, action.payload);
      case types.SET_USER_SUBMISSION:
        return handleSetUserSubmission(state, action.payload);
      case types.SET_ASSIGNMENT_COMMENTS:
        return handleSetComments(state, action.payload);
      case types.UPDATE_ASSIGNMENT_COMMENTS:
        return handleUpdateComments(state, action.payload);
      case types.SET_EDITED_ASSIGNMENT_COMMENT:
        return handleSetEditedComment(state, action.payload);
      case types.SET_DELETED_ASSIGNMENT_COMMENT:
        return handleSetDeletedComment(state, action.payload);

      case types.GET_ASSIGNMENTS:
        return {
          ...state,
          loading: true,
        };
      case types.GET_ASSIGNMENT_BY_ID:
        return {
          ...state,
          loading: true,
        };
      case types.GET_SUBMISSIONS:
        return {
          ...state,
          loading: true,
        };
      default:
        return state;
    }
  }
);

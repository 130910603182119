import { Button, Modal, Typography, Skeleton } from "antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import EntityCard from "../../../shared/EntityCard";
import AddEntity from "../../../shared/AddEntity";
import { ReactComponent as CourseIcon } from "../../../../assets/images/reusable-items/course.svg";
import testImage from "../../../../assets/images/default-cover-image.jpg";

const { Text } = Typography;

const ModuleDetailsScreen = (props: any) => {
  const {
    module,
    actions: { getModuleById, deleteModule },
  } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getModuleById({ id });
  }, []);

  const onDeleteModule = async () => {
    Modal.confirm({
      title: "Are you sure you want to delete this module?",
      onOk: async () => {
        await deleteModule({ id, navigate });
      },
    });
  };

  if (!module) {
    return <Skeleton active />;
  }

  return (
    <>
      {module && (
        <div className="grid grid-cols-2 w-full gap-8 pb-4">
          <div className="w-full">
            <Text className="text-neutral-600 font-semibold text-lg">
              Module Overview
            </Text>
            <div className="mt-3">
              <div className="bg-white rounded-lg p-6">
                <Text className="text-neutral-600 font-semibold text-sm">
                  Title
                </Text>
                <div className="w-100 mt-2 mb-6 min-h-[50px] bg-neutral-100 p-4 text-neutral-600 text-base font-semibold rounded-lg">
                  <Text>{module.title}</Text>
                </div>

                <Text className="text-neutral-600 font-semibold text-sm">
                  Description
                </Text>
                <div className="w-100 mt-2 mb-6 h-[156px] bg-neutral-100 p-4 text-neutral-600 text-base font-normal rounded-lg overflow-auto">
                  <Text>{module.description}</Text>
                </div>

                <Text className="text-neutral-600 font-semibold text-sm">
                  Cover Image
                </Text>

                <div>
                  <img
                    src={
                      module?.coverImageKey
                        ? `${process.env.REACT_APP_CLOUDFRONT_KEY}/${module?.coverImageKey}`
                        : testImage
                    }
                    alt="cover"
                    className="rounded-lg w-full  mt-2 mb-6"
                  />
                </div>
              </div>
              <div className="w-100 flex flex-row justify-end gap-4">
                <Button
                  danger
                  type="primary"
                  className="mt-4 w-2/4 h-[46px] text-base font-semibold"
                  onClick={onDeleteModule}
                >
                  Delete
                </Button>
                <Button
                  type="primary"
                  className="bg-primary-600 mt-4 w-2/4 h-[46px] text-base font-semibold"
                  onClick={() => navigate(`/educator/module/${id}/edit`)}
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Text className="text-neutral-600 font-semibold text-lg">
              Selected Courses
            </Text>
            <div className="h-0 min-h-[calc(100%-40px)] overflow-auto flex flex-col gap-4 mt-3">
              {module.courses && module?.courses?.length > 0 ? (
                module.courses.map((course: any) => (
                  <EntityCard
                    key={course.id}
                    title={course.title}
                    duration={course.duration || "0 hr 0 min"}
                    coverImageKey={course.coverImageKey || ""}
                    footer={
                      <Button
                        type="primary"
                        className="w-full h-6 bg-primary-600 "
                        key="view"
                        onClick={() =>
                          navigate(`/educator/course/${course.id}`)
                        }
                      >
                        View
                      </Button>
                    }
                    classes="w-full max-w-[507px] h-[200px]"
                  />
                ))
              ) : (
                <div>
                  <div className="h-full w-full flex items-center justify-center mt-2">
                    <AddEntity
                      firstButtonText="Select Courses"
                      firstButtonOnClick={() =>
                        navigate(
                          `/educator/module/${module.id}/assign-courses`,
                          {
                            state: { isEdit: true, module },
                          }
                        )
                      }
                      Icon={CourseIcon}
                      cardClasses="shadow-md max-w-[583px] w-full max-h-[400px] p-[50px]"
                      imageClasses="w-[150px] text-center mb-10"
                    />{" "}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModuleDetailsScreen;

import { useState, useEffect } from "react";
import { ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Input, Skeleton } from "antd";

import EntityCard from "../../../shared/EntityCard";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import EmptyState from "../../../shared/EmptyState";
import { CourseProps } from "../../../../utils";
import connector from "./Container";
import FilterButtons from "../../../shared/FilterButtons";
import { newsFeedType } from "../../../../utils/constants/newsFeedTypes";

const { Text } = Typography;

const UserCoursesScreen = (props: any) => {
  const {
    courses,
    coursesNewsFeed,
    loading,
    loaded,
    actions: { getCourse, getLearnerNewsFeed, deleteNewsFeed },
  } = props;

  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("ALL");
  const filterProps = [
    { key: "ALL", value: "ALL" },
    { key: "FINISHED", value: "FINISHED" },
    { key: "UNFINISHED", value: "UNFINISHED" },
  ];

  const coursePointsSum = courses?.reduce((accumulator: number, item: any) => {
    return item.isFinished ? accumulator + item.points : accumulator;
  }, 0);

  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) return;
    getCourse();
  }, []);

  useEffect(() => {
    getLearnerNewsFeed();
  }, []);

  useEffect(() => {
    if (coursesNewsFeed && coursesNewsFeed > 0) {
      deleteNewsFeed({ type: newsFeedType.course });
    }
  }, [coursesNewsFeed]);

  useEffect(() => {
    let filtered = courses;

    if (filter === "FINISHED") {
      filtered = filtered.filter((course: CourseProps) => course.isFinished);
    } else if (filter === "UNFINISHED") {
      filtered = filtered.filter((course: CourseProps) => !course.isFinished);
    }

    if (searchTerm) {
      filtered = filtered.filter((course: CourseProps) =>
        course.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredCourses(filtered);
  }, [searchTerm, filter, courses]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {courses && courses.length > 0 ? (
        <div>
          <div className="flex flex-row gap-[18px]">
            <Input
              placeholder="Search"
              className="w-1/2"
              prefix={<SearchIcon className="mx-3" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Text
              className={`w-1/2 flex justify-center items-center h-[50px] text-sm leading-[16px] font-semibold rounded-lg ${
                coursePointsSum === 0
                  ? "bg-neutral-200 text-neutral-500"
                  : "bg-rose-400 text-white"
              }  `}
            >
              {coursePointsSum === 0
                ? "No Points collected yet. Start a Course."
                : `You successfully collected ${coursePointsSum} Points.`}
            </Text>
          </div>
          <FilterButtons
            selectedFilter={filter}
            handleFilterChange={handleFilterChange}
            filterOptions={filterProps}
          />
          <div className="flex flex-wrap gap-4 mt-4  overflow-y-scroll max-h-[calc(100vh-290px)]">
            {filteredCourses?.length > 0 ? (
              filteredCourses.map((content: CourseProps, index: number) => (
                <EntityCard
                  key={content.id}
                  title={content.title}
                  coverImageKey={content.coverImageKey || ""}
                  numberOfEntities={content.lessonCount}
                  duration={content.duration || "0 hr 0 min"}
                  footer={
                    <div className="flex flex-row justify-between items-center gap-4">
                      <>
                        {!content.isFinished ? (
                          <div className="w-full bg-rose-400 border-none text-white py-[7px] rounded-lg h-8 flex flex-row flex-wrap items-baseline gap-1 justify-center">
                            <Text className="text-white text-base leading-[18px] font-semibold">
                              {content.points}
                            </Text>
                            <Text className="text-white text-[10px] leading-3">
                              PTS
                            </Text>
                          </div>
                        ) : (
                          <Text className="w-1/2 bg-success-550 text-white text-sm leading-[18px] py-[7px] rounded-lg h-8 font-semibold flex justify-center">
                            Finished
                          </Text>
                        )}
                        <Button
                          className={`bg-primary-600 text-white border-none text-sm leading-4 font-semibold ${
                            content.isFinished && "w-1/2"
                          }`}
                          onClick={() => navigate("/user/course/" + content.id)}
                        >
                          {!content.isFinished ? "View Lessons" : "Details"}
                        </Button>
                      </>
                    </div>
                  }
                />
              ))
            ) : (
              <div className="flex items-center w-full justify-center h-full py-[60px]">
                <EmptyState />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default UserCoursesScreen;

import { useEffect } from "react";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Card,
  Col,
  Row,
  Typography,
  Skeleton,
  Tooltip,
  Popconfirm,
  Button,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
import EmptyState from "../../../shared/EmptyState";
import VideoPlayer from "../../../shared/VideoPlayer";

const { Text } = Typography;

const AIContentScreen = (props: any) => {
  const {
    videos,
    loading,
    actions: { getVideo, deleteVideo },
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    getVideo();
  }, []);

  const handleCreateLesson = (video: any) => {
    navigate(`/educator/lesson/create`, { state: { video } });
  };

  if (loading) {
    return <Skeleton active />;
  }

  if (!videos.length) {
    return (
      <div className="flex items-center justify-center h-full pb-[60px]">
        <EmptyState />
      </div>
    );
  }

  return (
    <div>
      <Row gutter={[16, 16]}>
        {videos.map((video: any) => (
          <Col span={8} key={video.id}>
            <Card
              hoverable={video.status === "AVAILABLE" || video.status === "AVAILABLE_WITHOUT_TRANSCRIPTION"}
              cover={
                (video.status === "AVAILABLE" || video.status === "AVAILABLE_WITHOUT_TRANSCRIPTION") ? (
                  <VideoPlayer videoUri={video.videoUri} />
                ) : (video.status === "NOT_STARTED" || video.status === "NOT_PROCESSED" || video.status === "PROCESSING") ? (
                  <div>
                    <div className="aspect-video flex flex-col items-center justify-center p-2">
                      <Spin size="large" />
                      <Text type="secondary" className="mt-2">
                        Creating...
                      </Text>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="aspect-video flex flex-col items-center justify-center p-2">
                      <ExclamationCircleOutlined className="text-danger-500 text-3xl" />
                      <Text type="danger" className="mt-2">
                        Failed
                      </Text>
                    </div>
                  </div>
                )
              }
              title={
                <div className="flex justify-between items-center h-8">
                  <Text>{video.title}</Text>
                  {(video.status === "AVAILABLE" || video.status === "AVAILABLE_WITHOUT_TRANSCRIPTION") && (
                    <div className="flex gap-2 flex-shrink-0">
                      <Tooltip title="Create lesson with this video">
                        <Button
                          type="text"
                          className="text-primary-500"
                          icon={<PlusCircleOutlined />}
                          onClick={() => handleCreateLesson(video)}
                        />
                      </Tooltip>
                      <Tooltip title="Delete video">
                        <Popconfirm
                          title="Delete video"
                          description="Are you sure to delete this video?"
                          onConfirm={() => deleteVideo({ id: video.id })}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="text"
                            className="text-danger-500 "
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  )}
                  {video.status === "FAILED" && (
                    <div className="flex gap-2 flex-shrink-0">
                      <Tooltip title="Delete video">
                        <Popconfirm
                          title="Delete video"
                          description="Are you sure to delete this video?"
                          onConfirm={() => deleteVideo({ id: video.id })}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            type="text"
                            className="text-danger-500 "
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </Tooltip>
                    </div>
                  )}
                </div>
              }
              className="shadow-md p-4"
            ></Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default AIContentScreen;

import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Typography,
  Popconfirm,
  Skeleton,
  Tooltip,
  Input,
} from "antd";
import { useNavigate } from "react-router-dom";
import { QuizProps } from "../../../../utils";
import EmptyState from "../../../shared/EmptyState";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";

const { Text } = Typography;

interface ViewQuizzieProps {
  actions: {
    getQuizzie: () => void;
    deleteQuizzie: (params: { id: string }) => void;
  };
  quizzies: QuizProps[];
  loading: boolean;
  loaded: boolean;
}

const ViewQuizzie: React.FC<ViewQuizzieProps> = ({
  actions: { getQuizzie, deleteQuizzie },
  quizzies,
  loading,
  loaded,
}) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredQuizzies, setFilteredQuizzies] = useState(quizzies);

  useEffect(() => {
    setFilteredQuizzies(
      quizzies.filter((quizzie: any) =>
        quizzie.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, quizzies]);

  useEffect(() => {
    if (loaded) return;
    getQuizzie();
  }, [getQuizzie]);

  const confirmDeleteQuizzie = (id: string) => {
    deleteQuizzie({ id });
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div className="h-full">
      {quizzies && quizzies.length > 0 ? (
        <>
          <div className="flex flex-row gap-9 sticky">
            <Input
              placeholder="Search"
              prefix={<SearchIcon className="mx-3" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              type="primary"
              className="w-64 h-[50px] text-base leading-[18px] font-semibold"
              onClick={() => navigate("/educator/quizzie/create")}
            >
              Create New Quizzie
            </Button>
          </div>
          <div className="grid grid-cols-3 gap-4 mt-4 overflow-y-scroll max-h-[calc(100vh-238px)]">
            {filteredQuizzies?.length > 0 &&
              filteredQuizzies.map((quiz: QuizProps) => (
                <Card
                  key={quiz.id}
                  className="max-w-[350px] p-4 flex items-center"
                >
                  <div className="flex flex-col justify-between h-full gap-2">
                    <Text className="text-base font-medium">{quiz.title}</Text>

                    <div>
                      <div className="flex items-center gap-2 my-3">
                        <div
                          className="h-[30px] w-[30px] bg-primary-600 text-white font-semibold text-sm rounded-3xl text-center leading-[30px]"
                          style={{
                            boxShadow: "inset 0 2px 3px 0 rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          {quiz.questions.length}
                        </div>
                        <Text className="text-neutral-600 ">
                          Quizzie Questions
                        </Text>
                      </div>

                      <div className="border-t border-primary-200 pt-4 flex gap-4">
                        <Tooltip title="Create from existing">
                          <Button
                            onClick={() =>
                              navigate("/educator/quizzie/" + quiz.id)
                            }
                            type="primary"
                            className="w-full"
                          >
                            Create
                          </Button>
                        </Tooltip>
                        <Popconfirm
                          title="Delete quiz"
                          description="Are you sure to delete this quiz?"
                          onConfirm={() => confirmDeleteQuizzie(quiz.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button type="primary" danger className="w-full">
                            Delete
                          </Button>
                        </Popconfirm>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </div>
  );
};

export default ViewQuizzie;

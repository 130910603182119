import { Button, Row, Col, Input, Tooltip } from "antd";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import { useEffect, useState } from "react";
import ProgressInfoBox from "../components/ProgressInfoBox";
import FilterButtons from "../../../shared/FilterButtons";
import ActivityUserListItem from "../components/ActivityUserListItem";
import Chart from "../components/Chart";
import TimelineSelection from "../components/TimelineSelection";
import ListHeader from "../components/ListHeader";
import MenuItems from "../../../../assets/images/menu-items";
import { useNavigate } from "react-router-dom";
import { DownloadOutlined } from "@ant-design/icons";

const EducatorDashboardStudentsScreen = (props: any) => {
  const {
    users,
    benchmarkLoaded,
    benchmark,
    actions: { getUser, getBenchmarkAnalytics, exportExcel },
  } = props;

  const navigate = useNavigate();
  const [filter, setFilter] = useState("ALL");
  const [timeLineValue, setTimeLineValue] = useState();
  const [usersChartData, setUsersChartData] = useState([]);
  const [statistics, setStatistics] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  const filterProps = [
    { key: "All", value: "ALL" },
    { key: "Active", value: "ACTIVE" },
    { key: "Passive", value: "PASSIVE" },
  ];

  const timeLineOptions = [
    { value: "week", label: "Week" },
    { value: "month", label: "Month" },
    { value: "year", label: "Year" },
  ];

  useEffect(() => {
    document.body.classList.add("educator-dashboard-layout");

    return () => {
      document.body.classList.remove("educator-dashboard-layout");
    };
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (!benchmarkLoaded) {
      getBenchmarkAnalytics();
    }
  }, []);

  useEffect(() => {
    if (users && users.length > 0) {
      let filtered = getActiveUsers(users);
      if (filter === "ACTIVE") {
        filtered = filtered.filter((user: any) => user.isActive);
      } else if (filter === "PASSIVE") {
        filtered = filtered.filter((user: any) => !user.isActive);
      }

      if (searchTerm) {
        filtered = filtered.filter(
          (user: any) =>
            user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      setFilteredUsers(filtered);
    }
  }, [users, searchTerm, filter]);

  useEffect(() => {
    if (users && users.length > 0) {
      const activeUsers = getActiveUsers(users);
      const totalUsers = users.length;
      const activeUsersCount = activeUsers.filter(
        (user: any) => user.isActive
      ).length;
      const passiveUsersCount = totalUsers - activeUsersCount;
      const activeUsersPercentage = Math.round(
        (activeUsersCount / totalUsers) * 100
      );
      const passiveUsersPercentage = Math.round(
        (passiveUsersCount / totalUsers) * 100
      );

      setStatistics({
        activeUsersCount,
        activeUsersPercentage,
        passiveUsersCount,
        passiveUsersPercentage,
      });
    }
  }, [users]);

  useEffect(() => {
    if (benchmarkLoaded) {
      usersWeekData();
    }
  }, [benchmarkLoaded]);

  const handleChangeTimeline = (value: string) => {
    if (value === "week") {
      usersWeekData();
      return;
    }
    if (value === "month") {
      usersMonthData();
      return;
    }
    if (value === "year") {
      usersYearData();
      return;
    }
  };

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  const usersWeekData = () => {
    const data = benchmark?.users?.slice(0, 7);
    const chartData = getChartDataForWeek(data);
    setUsersChartData(chartData);
  };

  const usersMonthData = () => {
    const data = benchmark?.users?.slice(0, 30);
    const chartData = getChartDataForMonth(data);
    setUsersChartData(chartData);
  };

  const usersYearData = () => {
    const data = benchmark?.users?.slice(0, 365);
    const chartData = getChartDataForYear(data);
    setUsersChartData(chartData);
  };

  const getChartDataForWeek = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        weekday: "short",
      }),
    }));
  };

  const getChartDataForMonth = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).getDate(),
    }));
  };

  const getChartDataForYear = (data: any) => {
    return data?.map((dayData: any) => ({
      ...dayData,
      dateDay: new Date(dayData.createdAt).toLocaleDateString("en-US", {
        month: "short",
      }),
    }));
  };

  const exportUserExcelFile = () => {
    exportExcel({
      query: `entity=user&timeFrame=all`,
    });
  };

  const exportCurrentStateExcelFile = () => {
    exportExcel({
      query: `entity=user&timeFrame=today`,
    });
  };

  const getActiveUsers = (users: any) => {
    const SEVEN_DAYS_IN_MS = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    const currentDate = new Date().getTime();

    return users.map((user: any) => {
      const lastLoginDate = new Date(user.lastLoginDate).getTime();
      const isActive = currentDate - lastLoginDate <= SEVEN_DAYS_IN_MS;

      return {
        name: user.name,
        email: user.email,
        profilePictureKey: user.profilePictureKey,
        isActive: isActive,
      };
    });
  };

  return (
    <Row>
      <Col lg={12} className="pr-[80px]">
        <div className="max-w-[522px]">
          <div className="text-primary-600 text-xl font-semibold mb-2">
            Activity
          </div>
          <div className="text-neutral-500 text-sm">
            Monitor the activity of Students and filter the data by time frame
            (week, month, year).
          </div>

          <div className="mt-5 flex justify-between gap-4">
            <div className="flex gap-4">
              <TimelineSelection
                selectedOption={timeLineValue}
                onSelectionChange={handleChangeTimeline}
                options={timeLineOptions}
              ></TimelineSelection>
              <Tooltip title="Export">
                <Button
                  onClick={exportUserExcelFile}
                  icon={<DownloadOutlined />}
                  className="text-primary-500 leading-4 font-bold bg-neutral-100 border-none"
                ></Button>
              </Tooltip>
            </div>
            <Button
              color="primary"
              type="link"
              onClick={() => navigate("/educator/benchmark")}
            >
              All Graphs
            </Button>
          </div>
          <div className="mt-3">
            <Chart
              chartData={usersChartData}
              xAxisDataKey="dateDay"
              type="activity"
            ></Chart>
          </div>
          <div className="py-7">
            <Row className="h-full" gutter={24}>
              <Col lg={12}>
                <ProgressInfoBox
                  percentage={statistics?.activeUsersPercentage}
                  heading="Active Users"
                  totalNumber={statistics?.activeUsersCount}
                  type="activity"
                ></ProgressInfoBox>
              </Col>
              <Col lg={12}>
                <ProgressInfoBox
                  percentage={statistics?.passiveUsersPercentage}
                  heading="Inactive Users"
                  totalNumber={statistics?.passiveUsersCount}
                  type="activity"
                ></ProgressInfoBox>
              </Col>
            </Row>
          </div>
        </div>
      </Col>

      <Col lg={12} className="pl-[80px]">
        <Tooltip title="Export">
          <Button
            style={{
              position: "absolute",
              top: "-40px",
              right: "0px",
            }}
            onClick={exportCurrentStateExcelFile}
            icon={<DownloadOutlined />}
            className="text-primary-500 leading-4 font-bold bg-transparent border-none"
          ></Button>
        </Tooltip>
        <div className="bg-primary-100 w-full">
          <div className="bg-white p-5 w-full analytics-selector flex flex-col gap-4 rounded-lg">
            <div>
              <ListHeader title="Students" Icon={MenuItems.Users} />
            </div>
            <div className="analyitcs-searchbox sticky">
              <Input
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search"
                prefix={<SearchIcon className="" />}
                size="small"
              />
            </div>
          </div>
          <div className="flex justify-center mb-4">
            <FilterButtons
              selectedFilter={filter}
              handleFilterChange={handleFilterChange}
              filterOptions={filterProps}
            ></FilterButtons>
          </div>
          <div className="p-4 bg-white rounded-lg">
            <div className="flex flex-col gap-4 max-h-[calc(100vh-450px)] overflow-y-auto">
              <div>
                {filteredUsers && filteredUsers.length > 0 ? (
                  filteredUsers.map((user: any, index: number) => (
                    <ActivityUserListItem
                      user={user}
                      key={index}
                    ></ActivityUserListItem>
                  ))
                ) : (
                  <div className="flex flex-col gap-8 items-center">
                    <div className="p-4">
                      <MenuItems.User className="h-20 w-20 fill-primary-600" />
                    </div>
                    <div className="text-neutral-600 text-xl fill-primary-600 font-bold text-center w-3/4">
                      There are currently no available users.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default EducatorDashboardStudentsScreen;

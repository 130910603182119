import apiRoutes from "../../utils/constants/apiRoutes";
import ApiService from "../apiService";
import handleApiCall from "../handleApi";

export const createDiscussionService = async (data: any) =>
  handleApiCall(() => ApiService.post(apiRoutes.discussion, data));

export const getDiscussionsService = async () =>
  handleApiCall(() => ApiService.get(apiRoutes.discussion));

export const getDiscussionByIdService = async (id: string) =>
  handleApiCall(() =>
    ApiService.get(apiRoutes.discussionById.replace(":id", id))
  );

export const changeResolvedStatusDiscussionService = async (
  id: string,
  isResolved: boolean
) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.discussionById.replace(":id", id), {
      isResolved,
    })
  );

export const createDiscussionCommentService = async (
  id: string,
  comment: any
) =>
  handleApiCall(() =>
    ApiService.post(apiRoutes.discussionComment.replace(":id", id), { comment })
  );

export const editCommentDiscussionService = async (
  id: string,
  comment: boolean
) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.discussionCommentById.replace(":commentId", id), {
      comment,
    })
  );

export const deleteCommentDiscussionService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.discussionCommentById.replace(":commentId", id))
  );

export const likeCommentDiscussionService = async (
  id: string,
  isLiked: boolean
) =>
  handleApiCall(() =>
    ApiService.put(apiRoutes.discussionCommentLike.replace(":commentId", id), {
      isLiked,
    })
  );

export const deleteDiscussionService = async (id: string) =>
  handleApiCall(() =>
    ApiService.delete(apiRoutes.discussionById.replace(":id", id))
  );

import { useState } from "react";
import { Card, DatePicker, Divider, Typography } from "antd";
import { DownloadOutlined, EyeFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import { ReactComponent as UploadPDFIcon } from "../../../assets/images/upload-pdf.svg";
import AssignmentUploadedFiles from "./AssignmentUploadedFiles";

const { Text } = Typography;
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const AssignmentDetailsCard = ({
  assignment,
  presignedUrl,
  getPresignedUrl,
  removePresignedUrl,
}: any) => {
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const charMax = 225;

  return (
    <Card className="p-6 border-none shadow-sm">
      <div className="flex flex-col gap-6">
        <div>
          <Text className="text-neutral-600 text-sm font-semibold block">
            Assignment Title
          </Text>
          <Text className="bg-neutral-100 rounded-lg mt-2 p-4 block text-neutral-600 text-base font-semibold">
            {assignment?.title}
          </Text>
        </div>

        <div>
          <Text className="text-neutral-600 text-sm font-semibold block">
            Description
          </Text>
          <div className="bg-neutral-100 rounded-lg mt-2 p-4">
            <Text
              className={`text-neutral-600 text-base block overflow-hidden ${
                !showMoreDescription ? "max-h-[144px]" : "h-auto"
              } `}
            >
              {!showMoreDescription
                ? `${assignment?.description.substring(0, charMax)} ${
                    assignment?.description.length > charMax ? ".." : ""
                  } `
                : assignment?.description}
            </Text>
            {assignment?.description.length > charMax && (
              <Text
                onClick={() => setShowMoreDescription((prev) => !prev)}
                className="text-sm text-primary-600 font-semibold block mt-2 cursor-pointer w-fit"
              >
                {!showMoreDescription ? "More" : "Less"}
              </Text>
            )}
          </div>
        </div>

        <div className="flex gap-10">
          <div className="w-full">
            <Text className="text-neutral-600 text-sm font-semibold">
              Start Date
            </Text>

            <DatePicker
              size="large"
              className="w-full h-[50px] mt-2"
              value={dayjs(assignment?.startDate)}
              disabled
            />
          </div>
          <div className="w-full">
            <Text className="text-neutral-600 text-sm font-semibold">
              End Date
            </Text>

            <DatePicker
              size="large"
              className="w-full h-[50px] mt-2"
              value={dayjs(assignment?.endDate)}
              disabled
            />
          </div>
        </div>

        <div>
          <Text className="text-neutral-600 text-sm font-semibold block">
            Assignment
          </Text>

          <AssignmentUploadedFiles
            fileKey={assignment?.fileKey}
            presignedUrl={presignedUrl}
            getPresignedUrl={getPresignedUrl}
            removePresignedUrl={removePresignedUrl}
          />
        </div>
      </div>
    </Card>
  );
};

export default AssignmentDetailsCard;

import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../utils/types";
import {
  selectCourseStatistics,
  selectCourseStatisticsLoaded,
  selectCourseStatisticsLoading,
} from "../../../redux/learner/selector";
import { getCourseStatistics } from "../../../redux/learner/actions";

const mapStateToProps = (state: StateType) => ({
  courseStatistics: selectCourseStatistics(state),
  loading: selectCourseStatisticsLoading(state),
  loaded: selectCourseStatisticsLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getCourseStatistics,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);

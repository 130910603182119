import { call, all, put, takeLatest } from "redux-saga/effects";

import {
  assignmentTypes as types,
  setAssignments,
  setAssignmentById,
  getAssignments,
  setSubmissions,
  getSubmissions,
  setUserSubmission,
} from "../actions";
import { AxiosResponse } from "axios";

import { EntityIdPayload } from "../../../utils";
import showNotification from "../../../services/notificationService";
import {
  createAssignmentCommentService,
  createAssignmentService,
  deleteAssignmentByIdService,
  deleteAssignmentCommentService,
  editAssignmentCommentService,
  editAssignmentService,
  getAssignmentByIdService,
  getAssignmentsService,
  getSubmissionsService,
  getUserSubmissionService,
  gradeSubmissionService,
  userSubmitFiles,
} from "../../../services/educator/assignment";

export default function* educatorAssignmentSagas() {
  yield all([
    takeLatest(types.CREATE_ASSIGNMENT, handleCreateAssignment),
    takeLatest(types.GET_ASSIGNMENTS, handleGetAssignments),
    takeLatest(types.GET_ASSIGNMENT_BY_ID, handleGetAssignmentById),
    takeLatest(types.DELETE_ASSIGNMENT, handleDeleteAssignment),
    takeLatest(types.EDIT_ASSIGNMENT, handleEditAssignment),
    takeLatest(types.USER_SUBMIT_FILES, handleUserSubmitFiles),
    takeLatest(types.GET_SUBMISSIONS, handleGetSubmissions),
    takeLatest(types.GRADE_SUBMISSION, handleGradeSubmission),
    takeLatest(types.GET_USER_SUBMISSION, handleGetUserSubmission),
    takeLatest(types.CREATE_ASSIGNMENT_COMMENT, handleCreateAssignmentComment),
    takeLatest(types.EDIT_ASSIGNMENT_COMMENT, handleEditAssignmentComment),
    takeLatest(types.DELETE_ASSIGNMENT_COMMENT, handleDeleteAssignmentComment),
  ]);
}

function* handleCreateAssignment(action: any) {
  const { data }: AxiosResponse = yield call(
    createAssignmentService,
    action.payload.assignment
  );
  if (data) {
    yield put(getAssignments());
    showNotification("success", "Assignment created successfully!");
    action.payload.navigate(`/educator/assignment`);
  }
}

function* handleGetAssignments() {
  const { data }: AxiosResponse = yield call(getAssignmentsService);
  if (data) {
    yield put(setAssignments({ assignments: data }));
  }
}

function* handleGetAssignmentById(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getAssignmentByIdService,
    action.payload.id
  );
  if (data) {
    yield put(setAssignmentById({ assignment: data }));
  }
}

function* handleGetSubmissions(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getSubmissionsService,
    action.payload.id
  );
  if (data) {
    yield put(setSubmissions({ submissions: data }));
  }
}

function* handleGetUserSubmission(action: EntityIdPayload) {
  const { data }: AxiosResponse = yield call(
    getUserSubmissionService,
    action.payload.id
  );
  if (data) {
    yield put(setUserSubmission({ submission: data }));
  }
}

function* handleDeleteAssignment(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteAssignmentByIdService,
    action.payload.id
  );
  if (data) {
    showNotification("success", data.message);
    yield put(getAssignments());
  }
}

function* handleEditAssignment(action: any) {
  const { data }: AxiosResponse = yield call(
    editAssignmentService,
    action.payload.id,
    action.payload.assignment
  );
  if (data) {
    showNotification("success", "Assignment edited successfully!");
    yield put(setAssignmentById({ assignment: data }));
  }
}

function* handleUserSubmitFiles(action: any) {
  const { data }: AxiosResponse = yield call(
    userSubmitFiles,
    action.payload.id,
    action.payload.files
  );
  if (data) {
    showNotification("success", "File/s submitted successfully!");
    action.payload.navigate(`/user/assignment`);
    yield put(getAssignments());
  }
}

function* handleGradeSubmission(action: any) {
  const { data }: AxiosResponse = yield call(
    gradeSubmissionService,
    action.payload.id,
    action.payload.grade
  );
  if (data) {
    yield put(getSubmissions({ id: action.payload.assignmentId }));
  }
}

function* handleCreateAssignmentComment(action: any) {
  const { data }: AxiosResponse = yield call(
    createAssignmentCommentService,
    action.payload.id,
    action.payload.comment
  );
}

function* handleEditAssignmentComment(action: any) {
  const { data }: AxiosResponse = yield call(
    editAssignmentCommentService,
    action.payload.id,
    action.payload.comment
  );
}

function* handleDeleteAssignmentComment(action: any) {
  const { data }: AxiosResponse = yield call(
    deleteAssignmentCommentService,
    action.payload.id
  );
  if (data) {
    showNotification("success", data.message);
  }
}

export const discussionTypes = {
  CREATE_DISCUSSION: "CREATE_DISCUSSION",
  GET_DISCUSSIONS: "GET_DISCUSSIONS",
  SET_DISCUSSIONS: "SET_DISCUSSIONS",
  GET_DISCUSSION_BY_ID: "GET_DISCUSSION_BY_ID",
  SET_DISCUSSION_BY_ID: "SET_DISCUSSION_BY_ID",
  DELETE_DISCUSSION: "DELETE_DISCUSSION",
  SET_DELETED_DISCUSSION: "SET_DELETED_DISCUSSION",
  CHANGE_RESOLVED_STATUS: "CHANGE_RESOLVED_STATUS",
  SET_CHANGE_RESOLVED_STATUS: "SET_CHANGE_RESOLVED_STATUS",
  CREATE_COMMENT: "CREATE_COMMENT",
  SET_COMMENTS: "SET_COMMENTS",
  UPDATE_COMMENTS: "UPDATE_COMMENTS",
  EDIT_COMMENT: "EDIT_COMMENT",
  SET_EDITED_COMMENT: "SET_EDITED_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",
  SET_DELETED_COMMENT: "SET_DELETED_COMMENT",
  LIKE_COMMENT: "LIKE_COMMENT",
};

export const createDiscussion = (payload: any) => ({
  type: discussionTypes.CREATE_DISCUSSION,
  payload,
});

export const getDiscussions = () => ({
  type: discussionTypes.GET_DISCUSSIONS,
});

export const setDiscussions = (payload: any) => ({
  type: discussionTypes.SET_DISCUSSIONS,
  payload,
});

export const getDiscussionById = (id: any) => ({
  type: discussionTypes.GET_DISCUSSION_BY_ID,
  payload: id,
});

export const setDiscussionById = (payload: any) => ({
  type: discussionTypes.SET_DISCUSSION_BY_ID,
  payload,
});

export const deleteDiscussionById = (payload: any) => ({
  type: discussionTypes.DELETE_DISCUSSION,
  payload,
});

export const setDeletedDiscussionById = (payload: any) => ({
  type: discussionTypes.SET_DELETED_DISCUSSION,
  payload,
});

export const changeResolvedStatus = (payload: any) => ({
  type: discussionTypes.CHANGE_RESOLVED_STATUS,
  payload,
});

export const setChangeResolvedStatus = (payload: any) => ({
  type: discussionTypes.SET_CHANGE_RESOLVED_STATUS,
  payload,
});

export const createComment = (payload: any) => ({
  type: discussionTypes.CREATE_COMMENT,
  payload,
});

export const setComments = (payload: any) => ({
  type: discussionTypes.SET_COMMENTS,
  payload,
});

export const updateComments = (payload: any) => ({
  type: discussionTypes.UPDATE_COMMENTS,
  payload,
});

export const editComment = (payload: any) => ({
  type: discussionTypes.EDIT_COMMENT,
  payload,
});

export const setEditedComment = (payload: any) => ({
  type: discussionTypes.SET_EDITED_COMMENT,
  payload,
});

export const deleteComment = (payload: any) => ({
  type: discussionTypes.DELETE_COMMENT,
  payload,
});

export const setDeletedComment = (payload: any) => ({
  type: discussionTypes.SET_DELETED_COMMENT,
  payload,
});

export const likeComment = (payload: any) => ({
  type: discussionTypes.LIKE_COMMENT,
  payload,
});

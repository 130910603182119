import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  selectAssignment,
  selectAssignmentLoaded,
  selectAssignmentLoading,
  selectAssignments,
  selectUsers,
  selectUsersLoaded,
} from "../../../../redux/educator/selector";
import {
  deleteAssignment,
  getAssignmentById,
  getAssignments,
  getUser,
} from "../../../../redux/educator/actions";

const mapStateToProps = (state: StateType) => ({
  assignments: selectAssignments(state),
  assignment: selectAssignment(state),
  loading: selectAssignmentLoading(state),
  loaded: selectAssignmentLoaded(state),

  users: selectUsers(state),
  usersLoaded: selectUsersLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getAssignments,
      getAssignmentById,
      getUser,
      deleteAssignment,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);

import { Image, Typography } from "antd";
import testImage from "../../assets/images/default-cover-image.jpg";

const { Text } = Typography;
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const DashboardCardWithImage = ({ content }: any) => {
  return (
    <>
      {content && (
        <div className="w-1/2 bg-neutral-100 rounded h-[245px]">
          <div className="h-32">
            <Image
              src={
                content?.coverImageKey
                  ? `${cloudfrontKey}/${content.coverImageKey}`
                  : testImage
              }
              preview={false}
              className="rounded-t object-cover"
            />
          </div>
          <div className="my-2 ">
            <Text className="text-neutral-600 font-semibold text-xs line-clamp-2 h-8 mx-2">
              {content?.title}
            </Text>
            <div className="mx-2 bg-rose-400 border-none text-white p-[7px] rounded w-fit flex flex-row flex-wrap items-baseline gap-1 justify-center mt-1">
              <Text className="text-white text-base leading-[18px] font-semibold">
                {content?.points}
              </Text>
              <Text className="text-white text-[10px] leading-3">PTS</Text>
            </div>
            <Text className="text-primary-600 block text-sm mt-3 mx-2">
              <span className="text-[13px] font-semibold mr-1">
                {content?.lessonCount ?? content?.courseCount}
              </span>
              {content?.lessonCount ? "Lessons" : "Courses"}
            </Text>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardCardWithImage;

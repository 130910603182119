import { Button, } from "antd";
import {
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import EntityCard from "./EntityCard"; 

const SortableItem = ({ id, lesson, navigate }: any) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });
  
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };
  
    return (
      <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
        <EntityCard
          key={lesson.id}
          title={lesson.title}
          duration={lesson.duration || "0 hr 0 min"}
          coverImageKey={lesson?.coverImageKey || ""}
          footer={
           <></>
          }
          classes="w-full max-w-[507px] h-[200px]"
        />
      </div>
    );
  };

  
export default SortableItem;
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Card, Input, Skeleton } from "antd";

import { ReactComponent as AddIcon } from "../../../../assets/images/company-structure-items/add.svg";
import { assignmentProgress } from "../../../../utils/helpers/calculateAssignmentProgress";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import AssignmentActiveCard from "./AssignmentActiveCard";
import SingleAssignmentCard from "./SingleAssignmentCard";
import EmptyState from "../../../shared/EmptyState";

const { Text } = Typography;

const EducatorAssignmentsScreen = (props: any) => {
  const {
    assignments,
    assignment,
    loading,
    loaded,
    users,
    usersLoaded,
    actions: { getAssignments, getAssignmentById, getUser, deleteAssignment },
  } = props;

  const navigate = useNavigate();
  const [filteredAssignments, setFilteredAssignments] =
    useState<any>(assignments);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("ALL");
  const filterProps = [
    { key: "ALL", value: "ALL" },
    { key: "UNFINISHED", value: "UNFINISHED" },
    { key: "FINISHED", value: "FINISHED" },
  ];
  const allAssignments = filterProps[0];
  const unfinishedAssignments = filterProps[1];
  const finishedAssignments = filterProps[2];
  const [activeCard, setActiveCard] = useState<any>(null);

  useEffect(() => {
    if (!loaded) getAssignments();
    if (!usersLoaded) getUser();
  }, []);

  useEffect(() => {
    if (!activeCard) return;
    getAssignmentById({ id: activeCard.id });
  }, [activeCard]);

  useEffect(() => {
    if (assignments.length === 0 || window.innerWidth < 1200) return;
    document.body.classList.add("assignment-layout");

    return () => {
      document.body.classList.remove("assignment-layout");
    };
  }, [assignments]);

  useEffect(() => {
    let filtered = assignments;

    if (filter === "FINISHED") {
      filtered = filtered.filter(
        (assignment: any) => assignmentProgress(assignment) === 100
      );
    } else if (filter === "UNFINISHED") {
      filtered = filtered.filter(
        (assignment: any) => assignmentProgress(assignment) !== 100
      );
    }

    if (searchTerm) {
      filtered = filtered.filter((assignment: any) =>
        assignment.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredAssignments(filtered);
  }, [assignments, searchTerm, filter]);

  const handleDelete = () => {
    deleteAssignment({ id: assignment.id });
    setActiveCard(null);
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {assignments && assignments.length > 0 ? (
        <div className="xl:flex">
          <div className="xl:w-[42.4%] xl:pr-[70px]">
            <div className="sticky">
              <Input
                placeholder="Search"
                prefix={<SearchIcon className="mx-3" />}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="flex justify-between items-center xl:justify-center">
                <div className="flex  gap-5 my-5">
                  <Button
                    type="link"
                    onClick={() => {
                      setFilter(allAssignments.value);
                      setActiveCard(null);
                    }}
                    className={
                      filter === allAssignments.value
                        ? "text-primary-600 font-semibold px-0 text-sm"
                        : "text-neutral-400 font-normal px-0 text-sm"
                    }
                  >
                    {allAssignments.key}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setFilter(unfinishedAssignments.value);
                      setActiveCard(null);
                    }}
                    className={
                      filter === unfinishedAssignments.value
                        ? "text-primary-600 font-semibold px-0 text-sm"
                        : "text-neutral-400 font-normal px-0 text-sm"
                    }
                  >
                    {unfinishedAssignments.key}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setFilter(finishedAssignments.value);
                      setActiveCard(null);
                    }}
                    className={
                      filter === finishedAssignments.value
                        ? "text-primary-600 font-semibold px-0 text-sm"
                        : "text-neutral-400 font-normal px-0 text-sm"
                    }
                  >
                    {finishedAssignments.key}
                  </Button>
                </div>
                <div className="xl:hidden">
                  <Button
                    onClick={() => navigate("/educator/assignment/create")}
                    className="h-[40px]  border-none shadow-md rounded-lg"
                  >
                    <AddIcon />
                    <Text className="text-sm font-semibold text-neutral-600">
                      Add New Assignment
                    </Text>
                  </Button>
                </div>
              </div>

              <div>
                <div className="flex flex-col gap-4 pb-[60px]">
                  {activeCard && (
                    <>
                      <Card className={`p-6 border-none shadow-md  `}>
                        <SingleAssignmentCard
                          assignment={activeCard}
                          isActive
                        />
                      </Card>
                      <div className="border-b border-primary-200 my-3"></div>
                    </>
                  )}
                  {filteredAssignments && filteredAssignments.length > 0 ? (
                    filteredAssignments.map(
                      (assignment: any, index: number) => (
                        <Card
                          key={index}
                          onClick={() => setActiveCard(assignment)}
                          className={`p-4 py-6 cursor-pointer border-none shadow-sm 
                            ${assignment.id === activeCard?.id && "hidden"}
                           `}
                        >
                          <SingleAssignmentCard assignment={assignment} />
                        </Card>
                      )
                    )
                  ) : (
                    <div className="flex items-center justify-center h-full pb-[60px]">
                      <EmptyState />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="xl:w-[57.6%] xl:pl-[70px] pb-[60px]">
            <div className="hidden xl:flex justify-end mb-[72px]">
              <Button
                onClick={() => navigate("/educator/assignment/create")}
                className="h-[50px] w-1/2 border-none shadow-md rounded-lg"
              >
                <AddIcon />
                <Text className="text-sm font-semibold text-neutral-600">
                  Add New Assignment
                </Text>
              </Button>
            </div>
            {activeCard && (
              <AssignmentActiveCard
                percentage={activeCard}
                assignment={assignment}
                users={users}
                onDelete={handleDelete}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default EducatorAssignmentsScreen;

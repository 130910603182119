import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import {
  selectDiscussionLoaded,
  selectDiscussionLoading,
  selectDiscussions,
} from "../../../../redux/learner/selector/discussion";
import {
  deleteDiscussionById,
  getDiscussions,
} from "../../../../redux/learner/actions";

const mapStateToProps = (state: StateType) => ({
  discussions: selectDiscussions(state),
  loading: selectDiscussionLoading(state),
  loaded: selectDiscussionLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getDiscussions,
      deleteDiscussionById,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);

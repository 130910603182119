import { Route, Routes, useLocation } from "react-router-dom";
import { useState,useEffect } from "react";
import AuthenticationLayout from "../layout/AuthenticationLayout";
import Login from "../../modules/auth/login";
import Register from "../../modules/auth/Register";
import ResetPassword from "../../modules/auth/ResetPassword";
import EducatorLayout from "../layout/EducatorLayout";
import UserLayout from "../layout/UserLayout";
import SuccessPopUpLayout from "../layout/SuccessPopUpLayout";
import ChooseRole from "../../modules/auth/ChooseRole";
import Settings from "../../modules/shared/Settings";
import WildCardNavigation from "../../modules/shared/WildCardNavigation";
import AppDownload from "../../modules/auth/AppDownload";
import { Row } from "antd";

const App = () => {
  const location = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (screenWidth < 992) 
    switch (location.pathname) {
      case "/register":
        return (
          <Row className="bg-neutral-100 h-screen flex items-center justify-center p-5">
            <Register />
          </Row>
        )
      default:
        return <AppDownload />;
    }
  

  return (
    <>
      <Routes location={location}>
        <Route element={<AuthenticationLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>

        <Route path="/settings/:id" element={<Settings />} />

        <Route path="/choose-role" element={<ChooseRole />} />

        <Route path="/educator/*" element={<EducatorLayout />} />
        <Route path="/user/*" element={<UserLayout />} />

        <Route path="/success/*" element={<SuccessPopUpLayout />} />
        <Route path="*" element={<WildCardNavigation />} />
      </Routes>
    </>
  );
};

export default App;

import { createSelector } from "@reduxjs/toolkit";

import { LearnerCourseStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectCourseState = (state: StateType): LearnerCourseStateType =>
  state.learnerCourseReducer;

export const selectCourses = createSelector(
  selectCourseState,
  (state: LearnerCourseStateType) => state.courses
);

export const selectCourse = createSelector(
  selectCourseState,
  (state: LearnerCourseStateType) => state.course
);

export const selectCoursesLoading = createSelector(
  selectCourseState,
  (state: LearnerCourseStateType) => state.loading
);

export const selectCoursesLoaded = createSelector(
  selectCourseState,
  (state: LearnerCourseStateType) => state.loaded
);

export const selectLatestCourse = createSelector(
  selectCourseState,
  (state: LearnerCourseStateType) => state.latestCourse
);

export const selectLatestCourseLoaded = createSelector(
  selectCourseState,
  (state: LearnerCourseStateType) => state.latestCourseLoaded
);

import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import { getCourse } from "../../../../redux/learner/actions";
import {
  selectCourses,
  selectCoursesLoaded,
  selectCoursesLoading,
} from "../../../../redux/learner/selector";

const mapStateToProps = (state: StateType) => ({
  courses: selectCourses(state),
  loading: selectCoursesLoading(state),
  loaded: selectCoursesLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getCourse,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);

import { createSelector } from "reselect";
import { CourseStatistics } from "../../../utils/types/Learner";

const selectCourseStatisticsState = (
  state: any
): { courseStatistics: CourseStatistics[] } =>
  state.learnerCourseStatisticsReducer;

export const selectCourseStatistics = createSelector(
  selectCourseStatisticsState,
  (state: { courseStatistics: CourseStatistics[] }) => state.courseStatistics
);

export const selectCourseStatisticsLoading = createSelector(
  selectCourseStatisticsState,
  (state: any) => state.loading
);

export const selectCourseStatisticsLoaded = createSelector(
  selectCourseStatisticsState,
  (state: any) => state.loaded
);

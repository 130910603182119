export const courseTypes = {
  ADD_COURSE: "ADD_COURSE",
  GET_COURSE: "GET_COURSE",
  GET_COURSE_BY_ID: "GET_COURSE_BY_ID",
  DELETE_COURSE: "DELETE_COURSE",
  SET_COURSE: "SET_COURSE",
  SET_COURSE_BY_ID: "SET_COURSE_BY_ID",
  EDIT_COURSE: "EDIT_COURSE",
  ASSIGN_LESSON: "ASSIGN_LESSON",
  CHANGE_PUBLISH_STATUS: "CHANGE_PUBLISH_STATUS",
  UPDATE_LESSON_ORDER: "UPDATE_LESSON_ORDER",
};

export const addCourse = (payload: any) => ({
  type: courseTypes.ADD_COURSE,
  payload,
});

export const getCourse = () => ({
  type: courseTypes.GET_COURSE,
});

export const setCourse = (payload: any) => ({
  type: courseTypes.SET_COURSE,
  payload,
});

export const getCourseById = (id: any) => ({
  type: courseTypes.GET_COURSE_BY_ID,
  payload: id,
});

export const setCourseById = (payload: any) => ({
  type: courseTypes.SET_COURSE_BY_ID,
  payload,
});

export const deleteCourse = (payload: any) => ({
  type: courseTypes.DELETE_COURSE,
  payload,
});

export const editCourse = (payload: any) => ({
  type: courseTypes.EDIT_COURSE,
  payload,
});

export const assignLesson = (payload: any) => ({
  type: courseTypes.ASSIGN_LESSON,
  payload,
});

export const changePublishStatus = (payload: any) => ({
  type: courseTypes.CHANGE_PUBLISH_STATUS,
  payload,
});

export const updateLessonOrder = (payload: any) => ({
  type: courseTypes.UPDATE_LESSON_ORDER,
  payload,
});
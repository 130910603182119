import { Button, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";

import { LessonProps } from "../../../../utils";

const { Text } = Typography;

const LessonAccordion = ({
  lesson,
  previousLesson,
}: {
  lesson: LessonProps;
  previousLesson: LessonProps;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const accordionBgColor = (lesson: any) => {
    switch (true) {
      case lesson?.isFinished:
        return "bg-neutral-100";
      case (!lesson?.isFinished && previousLesson?.isFinished) ||
        previousLesson == undefined:
        return "bg-primary-200";
      default:
        return "bg-neutral-100";
    }
  };

  return (
    <Button
      disabled={previousLesson?.isFinished == false}
      className={`w-full px-4 rounded-lg ${accordionBgColor(
        lesson
      )} border-none mb-[6px] shadow-none h-[56px] text-base font-semibold text-neutral-600 flex justify-between items-center ${
        lesson?.isFinished && "cursor-default"
      }`}
      onClick={() =>
        !lesson?.isFinished && navigate(`/user/course/${id}/lesson`)
      }
    >
      <div className="truncate">
        {lesson?.title} {lesson?.orderIndex !== undefined && lesson?.orderIndex}
      </div>
      {lesson?.isFinished ? (
        <CheckOutlined className="text-success-550 text-xl" />
      ) : (
        ""
      )}
    </Button>
  );
};

export default LessonAccordion;

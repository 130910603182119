import { useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Layout, Row, Col, Divider } from "antd";

import HeaderItems from "../../../assets/images/header-items";
import UserHeaderProgressItem from "./HeaderProgressItem";
import { useSelector } from "react-redux";
import { selectStepper, selectTitles } from "../../../redux/shared/selectors";

const { Header } = Layout;
const LearnerHeader = () => {
  const { pathname } = useLocation();
  const stepper = useSelector(selectStepper);
  const titles = useSelector(selectTitles);

  const [headerValues, setHeaderValues] = useState<any>(
    new Map([
      [
        "/user/home",
        {
          Icon: HeaderItems.Home,
          title: "Welcome to your Home",
          hasStepper: false,
        },
      ],
      [
        "/user/dashboard",
        {
          Icon: HeaderItems.Dashboard,
          title: "Welcome to your Dashboard",
          subTitle:
            "Check overall progress of your Classes, Lessons and Discussions",
          hasStepper: false,
        },
      ],
      [
        "/user/module",
        {
          Icon: HeaderItems.Module,
          title: "Welcome to your Modules",
          subTitle:
            "Monitor all Modules and Courses. Collect your Points by finishing the Courses in every Module.",
          hasStepper: false,
        },
      ],
      [
        "/user/module/:id",
        {
          Icon: HeaderItems.Module,
          title: "Welcome to your Modules",
          subTitle:
            "Monitor all Modules and Courses and by finishing them collect your Points",
          hasStepper: false,
        },
      ],
      [
        "/user/course",
        {
          Icon: HeaderItems.Course,
          title: "Welcome to your Courses",
          subTitle:
            "Monitor all Courses and Lessons. Collect your Points by finishing the Lessons in every Course.",
          hasStepper: false,
        },
      ],
      [
        "/user/course/:id",
        {
          Icon: HeaderItems.Course,
          title: "Welcome to your Courses",
          subTitle:
            "Monitor all Courses and Lessons and by finishing them collect your Points",
          hasStepper: false,
        },
      ],
      [
        "/user/course/:id/lesson",
        {
          Icon: HeaderItems.Course,
          title: "Course",
          hasStepper: true,
          hasTitles: true,
        },
      ],
      [
        "/user/quiz/results",
        {
          Icon: HeaderItems.Course,
          title: "Course",
          hasStepper: false,
        },
      ],
      [
        "/user/quizzie",
        {
          Icon: HeaderItems.Quizzie,
          title: "Quizzie",
          hasStepper: false,
        },
      ],
      [
        "/user/quizzie/results",
        {
          Icon: HeaderItems.Quizzie,
          title: "Quizzie",
          hasStepper: false,
        },
      ],
      [
        "/user/quizzie/:id",
        {
          Icon: HeaderItems.Quizzie,
          title: "Quizzie",
          hasStepper: true,
        },
      ],
      [
        `/user/alert`,
        {
          Icon: HeaderItems.Alert,
          title: "My Alerts",
          hasStepper: false,
        },
      ],
      [
        "/user/discussion",
        {
          Icon: HeaderItems.Discussion,
          title: "Start Discussion",
          hasStepper: false,
        },
      ],
      [
        "/user/discussion/results",
        {
          Icon: HeaderItems.Discussion,
          title: "Discussion",
          hasStepper: false,
        },
      ],
      [
        "/user/discussion/create",
        {
          Icon: HeaderItems.Discussion,
          title: "Start Discussion",
          hasStepper: false,
        },
      ],
      [
        "/user/assignment",
        {
          Icon: HeaderItems.Assignment,
          title: "Assignments",
          hasStepper: false,
        },
      ],
      [
        "/user/assignment/:id",
        {
          Icon: HeaderItems.Assignment,
          title: "Complete Assignment",
          hasStepper: false,
        },
      ],
    ])
  );
  const [CurrentHeaderValue, setCurrentHeaderValue] = useState<any>(null);

  useEffect(() => {
    getHeaderPropsForCurrentPath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const getHeaderPropsForCurrentPath = () => {
    const mapKeysArr = Array.from(headerValues.keys());
    const currentValueByPath = mapKeysArr.find((key: any) =>
      matchPath(key, pathname)
    );

    const headerValueToSet = headerValues.get(currentValueByPath);
    if (headerValueToSet) {
      setCurrentHeaderValue(headerValueToSet);
      return;
    }

    setCurrentHeaderValue(null);
  };

  return (
    <>
      {CurrentHeaderValue && (
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="educator-header-container h-full flex items-center justify-between">
            <Row className="w-full justify-between">
              <Col
                lg={
                  CurrentHeaderValue.hasTitles || CurrentHeaderValue.subTitle
                    ? "auto"
                    : 8
                }
                className="flex items-center gap-5"
              >
                <CurrentHeaderValue.Icon className="min-w-[30px] h-[30px] fill-primary-600 text-3xl text-primary-600" />
                <div>
                  <div className="text-sm font-semibold">
                    {CurrentHeaderValue.title}
                  </div>
                  {CurrentHeaderValue.subTitle && (
                    <div className="text-xs">{CurrentHeaderValue.subTitle}</div>
                  )}
                </div>
                {CurrentHeaderValue.hasTitles && (
                  <>
                    <Divider type="vertical" className=" bg-white h-8" />
                    <div className="text-sm">
                      <div className="text-primary-400 line-clamp-1">
                        {titles.course}
                      </div>
                      <div className="font-semibold line-clamp-1">
                        {titles.lesson || titles.quiz}
                      </div>
                    </div>
                  </>
                )}
              </Col>
              <Col
                lg={CurrentHeaderValue.subTitle ? 4 : 16}
                xl={CurrentHeaderValue.subTitle ? 7 : 16}
                className="flex gap-2 max-w-[600px] items-center mt-1"
              >
                {CurrentHeaderValue?.hasStepper &&
                  stepper.map((item, index) => (
                    <UserHeaderProgressItem key={index} isFilled={item} />
                  ))}
              </Col>
            </Row>
          </div>
        </Header>
      )}
    </>
  );
};

export default LearnerHeader;

import { Button, Card, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AssignmentDetailsCard from "../../../shared/Assignment/AssignmentDetailsCard";
import ViewSubmissions from "./ViewSubmissions";
import GradeSubmission from "./GradeSubmission";
import AssignmentUploadedFiles from "../../../shared/Assignment/AssignmentUploadedFiles";
import AssignmentDiscussionCard from "../../../shared/Assignment/AssignmentDiscussionCard";

const { Text } = Typography;

const EducatorAssignmentsGrade = (props: any) => {
  const {
    assignment,
    submissions,
    userSubmission,
    presignedUrl,
    loading,
    actions: {
      getAssignmentById,
      getSubmissions,
      gradeSubmission,
      getUserSubmission,
      getPresignedUrl,
      removePresignedUrl,
    },
  } = props;
  const id = useParams().id;
  const [activeSubmission, setActiveSubmission] = useState<any>(null);
  const [grade, setGrade] = useState<null | number>(null);

  useEffect(() => {
    id && getAssignmentById({ id });
    id && getSubmissions({ id });
  }, [id]);

  useEffect(() => {
    if (window.innerWidth < 1200) return;
    document.body.classList.add("assignment-grade-layout");

    return () => {
      document.body.classList.remove("assignment-grade-layout");
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1200) return;

    activeSubmission
      ? document.body.classList.remove("assignment-grade-layout")
      : document.body.classList.add("assignment-grade-layout");
  }, [activeSubmission]);

  const handleGrade = () => {
    gradeSubmission({
      id: activeSubmission?.submissionId,
      grade,
      assignmentId: activeSubmission.assignmentId,
    });
    setActiveSubmission(null);
  };

  useEffect(() => {
    activeSubmission &&
      getUserSubmission({ id: activeSubmission.submissionId });
  }, [activeSubmission]);

  if (loading) return <Skeleton active />;

  return (
    <div>
      <div className="xl:flex pb-[60px]">
        <div className="xl:w-1/2 xl:pr-[70px] pb-[60px] xl:pb-0">
          <AssignmentDetailsCard
            assignment={assignment}
            presignedUrl={presignedUrl}
            getPresignedUrl={getPresignedUrl}
            removePresignedUrl={removePresignedUrl}
          />
        </div>
        <div className={`xl:w-1/2 ${!activeSubmission && " xl:pl-[70px]"}`}>
          {!activeSubmission ? (
            <ViewSubmissions
              submissions={submissions}
              activeSubmission={activeSubmission}
              setActiveSubmission={setActiveSubmission}
            />
          ) : (
            <>
              <GradeSubmission
                activeSubmission={activeSubmission}
                setActiveSubmission={setActiveSubmission}
                grade={grade}
                setGrade={setGrade}
              />
              <Card className="p-6 border-none shadow-sm mt-8">
                <Text className="text-neutral-600 text-sm font-semibold">
                  Uploaded Assignments
                </Text>
                <div className="mt-6">
                  {userSubmission?.fileKeys.map(
                    (fileKey: any, index: number) => (
                      <div key={index}>
                        <AssignmentUploadedFiles
                          fileKey={fileKey}
                          presignedUrl={presignedUrl}
                          getPresignedUrl={getPresignedUrl}
                          removePresignedUrl={removePresignedUrl}
                        />
                      </div>
                    )
                  )}
                </div>
              </Card>

              <AssignmentDiscussionCard
                activeCard={assignment}
                isDisabledToComment={true}
                isAssignment={true}
              />

              {!activeSubmission.grade && (
                <Button
                  onClick={handleGrade}
                  type="primary"
                  disabled={grade === null}
                  className="h-[50px] w-1/2 rounded-lg mt-10"
                >
                  Submit
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EducatorAssignmentsGrade;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, Skeleton, Typography, Upload, UploadFile } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { ReactComponent as UploadPDFIcon } from "../../../../assets/images/upload-pdf.svg";
import AssignmentUploadedFiles from "../../../shared/Assignment/AssignmentUploadedFiles";
import AssignmentDetailsCard from "../../../shared/Assignment/AssignmentDetailsCard";
import { selectUser } from "../../../../redux/auth/selector";
import AssignmentDiscussionCard from "../../../shared/Assignment/AssignmentDiscussionCard";

const { Text } = Typography;
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const SingleAssignmentScreen = (props: any) => {
  const {
    assignment,
    presignedUrl,
    loading,
    actions: {
      getAssignmentById,
      userSubmitFiles,
      getPresignedUrl,
      removePresignedUrl,
    },
  } = props;
  const id = useParams().id;
  const navigate = useNavigate();
  const userId = useSelector(selectUser)?.id;

  const [pdfFileList, setPdfFileList] = useState<any>([]);
  const [submission, setSubmission] = useState<any>(null);

  useEffect(() => {
    id && getAssignmentById({ id });
  }, [id]);

  useEffect(() => {
    if (assignment?.submissions.length === 0) return;

    const userSubmission = assignment?.submissions.find(
      (submission: any) => submission.userId === userId
    );
    setSubmission(userSubmission);
  }, [assignment]);

  const handlePdfChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setPdfFileList(fileList);
  };

  const removePdf = (uid: string) => {
    const newPdfFileList = pdfFileList.filter(
      (file: UploadFile) => file.uid !== uid
    );
    setPdfFileList(newPdfFileList);
  };

  const handleSubmit = () => {
    const formData = new FormData();

    pdfFileList.forEach((file: any) => {
      formData.append("files", file.originFileObj as File);
    });

    userSubmitFiles({ files: formData, id: assignment.id, navigate });
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <div>
      <div className="xl:flex gap-10 pb-[60px]">
        <div className="xl:w-1/2 mb-6 xl:mb-0">
          <AssignmentDetailsCard
            assignment={assignment}
            presignedUrl={presignedUrl}
            getPresignedUrl={getPresignedUrl}
            removePresignedUrl={removePresignedUrl}
          />
        </div>
        <div className="xl:w-1/2 ">
          <Card className="p-6 border-none shadow-sm">
            {assignment?.submissions.length === 0 ? (
              <>
                <Text className="text-neutral-600 text-sm font-semibold">
                  Upload Assignments
                </Text>
                <Upload
                  name="pdf"
                  listType="picture-card"
                  maxCount={4}
                  multiple
                  disabled={pdfFileList.length > 3}
                  beforeUpload={() => false}
                  accept="application/pdf"
                  className="bg-neutral-100 rounded-lg mt-2 mb-6"
                  fileList={pdfFileList}
                  onChange={handlePdfChange}
                  showUploadList={false}
                  iconRender={() => (
                    <div className="w-full h-full flex items-center justify-center">
                      <UploadPDFIcon />
                    </div>
                  )}
                >
                  <div className="flex flex-col gap-1 items-center justify-center h-[160px] w-full bg-neutral-100 rounded-lg">
                    <UploadPDFIcon />
                    <p className="text-neutral-600 text-base font-medium mt-3">
                      Upload a PDF file/s
                    </p>
                    <p className="text-xs text-neutral-500 font-normal">
                      File size up to 5MB
                    </p>
                  </div>
                </Upload>
                {pdfFileList.length > 0 && (
                  <>
                    <Text className="text-neutral-600 text-sm font-semibold">
                      Uploaded Assignments {pdfFileList.length}/4
                    </Text>
                    {pdfFileList.map((file: any, index: number) => (
                      <div
                        key={index}
                        className="w-full h-16 px-5 mt-2 rounded-lg bg-neutral-100 text-neutral-600 text-base flex items-center justify-between border border-primary-450  border-dashed"
                      >
                        <div className="flex gap-2 items-center">
                          <UploadPDFIcon
                            style={{
                              width: 30,
                              height: 30,
                            }}
                          />
                          <Text>{file.name}</Text>
                        </div>
                        <div className="flex gap-2 items-center">
                          <CloseOutlined
                            onClick={() => removePdf(file.uid)}
                            className="text-base text-primary-450 cursor-pointer"
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            ) : (
              <>
                <Text className="text-neutral-600 text-sm font-semibold">
                  Uploaded Assignments
                </Text>

                <div className="mt-6">
                  {submission &&
                    submission?.fileKeys.map((fileKey: any, index: number) => (
                      <div key={index}>
                        <AssignmentUploadedFiles
                          fileKey={fileKey}
                          presignedUrl={presignedUrl}
                          getPresignedUrl={getPresignedUrl}
                          removePresignedUrl={removePresignedUrl}
                        />
                      </div>
                    ))}
                </div>
              </>
            )}
          </Card>

          {assignment?.submissions[0]?.grade && (
            <Card className="py-6 mt-6 rounded-lg shadow-sm">
              <div className="h-full flex flex-col gap-1 items-center justify-center">
                <Text className="text-neutral-600 text-sm font-semibold">
                  Grade
                </Text>

                <Text className="text-success-550 text-xl font-semibold">
                  {assignment?.submissions[0]?.grade}/100
                </Text>
              </div>
            </Card>
          )}

          <AssignmentDiscussionCard
            activeCard={assignment}
            isDisabledToComment={false}
            isAssignment={true}
          />

          {assignment?.submissions.length === 0 && (
            <div className="flex justify-end">
              <Button
                disabled={pdfFileList.length === 0}
                onClick={handleSubmit}
                type="primary"
                className="w-1/2 h-[50px] mt-10 rounded-lg"
              >
                Finish
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleAssignmentScreen;

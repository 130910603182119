import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Typography } from "antd";
import { io, Socket } from "socket.io-client";

import { selectUser } from "../../../redux/auth/selector";
import DiscussionComments from "../Discussion/DiscussionComments";
import CreateComment from "../Discussion/CreateComment";
import showNotification from "../../../services/notificationService";
import { assignmentTypes } from "../../../redux/educator/actions";
import { selectAssignmentComments } from "../../../redux/educator/selector";

const { Text } = Typography;

const AssignmentDiscussionCard = ({
  activeCard,
  isDisabledToComment,
  isAssignment,
}: {
  activeCard: any;
  isDisabledToComment?: boolean;
  isAssignment: boolean;
}) => {
  const assignmentId = activeCard?.id;
  const [showAddReplyInput, setShowAddReplyInput] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [socket, setSocket] = useState<Socket | null>(null);
  const user = useSelector(selectUser);
  const comments = useSelector(selectAssignmentComments);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    const token = localStorage.getItem("accessToken");
    const newSocket = io(process.env.REACT_APP_SOCKET_BASE_URL, {
      extraHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
    setSocket(newSocket);

    newSocket.on("connect", () => {
      // Join the discussion room
      newSocket.emit("joinAssignment", assignmentId);
    });

    newSocket.on("assignment:comment", (comment) => {
      dispatch({
        type: assignmentTypes.UPDATE_ASSIGNMENT_COMMENTS,
        payload: {
          comment: comment,
        },
      });
    });

    newSocket.on("assignment:commentUpdated", (comment) => {
      dispatch({
        type: assignmentTypes.SET_EDITED_ASSIGNMENT_COMMENT,
        payload: {
          comment: comment,
        },
      });
    });

    newSocket.on("assignment:commentDeleted", (commentId) => {
      dispatch({
        type: assignmentTypes.SET_DELETED_ASSIGNMENT_COMMENT,
        payload: {
          commentId,
        },
      });
    });

    newSocket.on("error", (error) => {
      console.error("Socket error:", error);
      showNotification("error", error.message);
    });

    return () => {
      newSocket.emit("leaveAssignment", assignmentId); // Leave the room before disconnecting
      newSocket.disconnect();
    };
  }, [assignmentId, user]);

  useEffect(() => {
    dispatch({
      type: assignmentTypes.SET_ASSIGNMENT_COMMENTS,
      payload: {
        comments: activeCard?.assignmentComments,
      },
    });
  }, [assignmentId]);

  useEffect(() => {
    setShowAddReplyInput(false);
  }, [activeCard]);

  const handleCommentSubmit = async () => {
    dispatch({
      type: assignmentTypes.CREATE_ASSIGNMENT_COMMENT,
      payload: {
        id: assignmentId,
        comment: newComment,
      },
    });
    setNewComment("");
    setShowAddReplyInput(false);
  };

  return (
    <Card className={`p-6 duration-200 shadow-sm mt-6 border-none `}>
      <Text className="text-primary-600 text-sm font-semibold block">
        {isDisabledToComment && comments?.length === 0
          ? "No Discussions yet"
          : "Discussion"}
      </Text>

      <DiscussionComments
        comments={comments}
        currentUserId={user?.id}
        isDisabledToComment={isDisabledToComment}
        isAssignment={isAssignment}
      />

      {!isDisabledToComment && (
        <CreateComment
          showAddReplyInput={showAddReplyInput}
          setShowAddReplyInput={setShowAddReplyInput}
          newComment={newComment}
          setNewComment={setNewComment}
          handleCommentSubmit={handleCommentSubmit}
          isStarted={comments?.length > 0}
          isAssignment={isAssignment}
        />
      )}
    </Card>
  );
};
export default AssignmentDiscussionCard;

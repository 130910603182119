import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import { getModule } from "../../../../redux/educator/actions";
import {
  selectModuleLoaded,
  selectModules,
  selectModulesLoading,
} from "../../../../redux/educator/selector";

const mapStateToProps = (state: StateType) => ({
  modules: selectModules(state),
  loading: selectModulesLoading(state),
  loaded: selectModuleLoaded(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      getModule,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);

import { Form, Input, Button, Typography, Row, Col } from "antd";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import DepartmentSelector from "../../../shared/DepartmentSelector";
import PositionSelector from "../../../shared/PositionSelector";
import UserSelector from "../../../shared/UserSelector";
import showNotification from "../../../../services/notificationService";

const { Text } = Typography;
const { TextArea } = Input;

const CreateAlertScreen = (props: any) => {
  const {
    actions: { addAlert, setInitialSteps },
  } = props;

  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();

  const { fromExisting = false, alert = {} } = location.state || {};

  useEffect(() => {
    setInitialSteps(1);

    if (fromExisting && alert) {
      setSelectedDepartments(alert.departmentIds || []);
      setSelectedPositions(alert.positionIds || []);
      setSelectedUsers(alert.userIds || []);
    }
  }, [fromExisting, alert]);

  const handleSubmit = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => {
    if (
      !selectedDepartments.length &&
      !selectedPositions.length &&
      !selectedUsers.length
    ) {
      showNotification(
        "error",
        "Please select at least one department, position or user"
      );
      return;
    }
    addAlert({
      alert: {
        title,
        description,
        departmentIds: selectedDepartments,
        positionIds: selectedPositions,
        userIds: selectedUsers,
      },
      navigate,
    });
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);
  };

  const handleUserChange = (users: string[]) => {
    setSelectedUsers(users);
  };

  const handleCancel = () => {
    navigate("/educator/alert");
  };

  return (
    <Row className="h-full">
      <Col lg={8}>
        <CreateEntityInfo
          title="Create a new Alert"
          text="You can create an Alert here by adding all necessary information."
        />
      </Col>
      <Col
        lg={16}
        className="h-full flex flex-col justify-between max-w-[600px]"
      >
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          className="pt-5"
          initialValues={{
            title: fromExisting && alert ? alert.title : "",
            description: fromExisting && alert ? alert.description : "",
          }}
        >
          <Text className="text-neutral-600 text-sm font-semibold">Title</Text>
          <Form.Item
            name="title"
            className="mt-2"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Type the title here" />
          </Form.Item>

          <Text className="text-neutral-600 text-sm font-semibold">
            Description
          </Text>
          <Form.Item
            name="description"
            className="mt-2"
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <TextArea placeholder="Add description here" rows={10} />
          </Form.Item>

          <div className="w-full flex flex-col">
            <Text className="text-neutral-600 text-sm font-semibold">
              Assign Alert
            </Text>
            <Text className="text-neutral-600 text-xs font-normal">
              You can assign alert to one of categories or all
            </Text>
          </div>
          <div className="mt-2 flex flex-col gap-2">
            <DepartmentSelector
              onSelectionChange={handleDepartmentChange}
              selectedDepartments={selectedDepartments}
              setSelectedDepartments={setSelectedDepartments}
            />
            <PositionSelector
              onSelectionChange={handlePositionChange}
              selectedPositions={selectedPositions}
              setSelectedPositions={setSelectedPositions}
            />
            <UserSelector
              onSelectionChange={handleUserChange}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
          </div>

          <div className="w-full flex mt-5 gap-5">
            <Button
              onClick={handleCancel}
              className="w-full h-[50px] text-base  text-primary-600 border-primary-200 font-semibold rounded-lg"
            >
              Cancel
            </Button>
            <Form.Item className="w-full">
              <Button
                type="primary"
                htmlType="submit"
                className="w-full bg-primary-600 h-[50px] font-semibold text-base"
              >
                Add Alert
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default CreateAlertScreen;

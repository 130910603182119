import React, { useEffect, useState } from "react";
import { Avatar, Button, Card, Image, Input, Tooltip, Typography } from "antd";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { formatTimeAgo } from "../../../utils/helpers";
import { DiscussionCommentType } from "../../../utils";
import LikeIcon from "./LikeIcon";

const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;
const { TextArea } = Input;
const { Text } = Typography;

const DiscussionComment = ({
  comment,
  currentUserId,
  isEditing,
  setEditingItem,
  updatedComment,
  setUpdatedComment,
  onSave,
  onCancelEdit,
  deleteComment,
  handleLike,
  isDisabledToComment,
  isAssignment,
}: {
  comment: DiscussionCommentType;
  currentUserId: string;
  isEditing: boolean;
  setEditingItem: any;
  updatedComment: string;
  setUpdatedComment: React.Dispatch<React.SetStateAction<string>>;
  onSave: () => void;
  onCancelEdit: () => void;
  deleteComment: (id: string) => void;
  handleLike: (id: string, isLiked: boolean | undefined) => void;
  isDisabledToComment?: boolean;
  isAssignment: boolean;
}) => {
  const isLiked = comment?.likedBy?.includes(currentUserId);

  return (
    <>
      <Card key={comment.id} className={`my-6 border-none bg-primary-50`}>
        <div className="flex flex-col gap-6 p-6">
          <div className="flex gap-2">
            <div>
              {!comment?.user?.profilePictureKey ? (
                <Avatar size={40} icon={<UserOutlined />} />
              ) : (
                <Avatar
                  size={40}
                  src={`${cloudfrontKey}/${comment?.user?.profilePictureKey}`}
                />
              )}
            </div>
            <div className="flex flex-col justify-between">
              <Text className="text-base leading-[18px] font-semibold text-neutral-600">
                {comment?.user?.name ?? comment?.user?.email}
              </Text>
              <div className="text-sm leading-4 text-neutral-500">
                {formatTimeAgo(comment?.createdAt)}
              </div>
            </div>
          </div>
          {!isEditing ? (
            <div className="text-base text-neutral-600 font-semibold break-words">
              {comment.comment}
            </div>
          ) : (
            <div className="w-full border border-primary-300 rounded-lg">
              <TextArea
                autoSize
                value={updatedComment}
                onChange={(e) => setUpdatedComment(e.target.value)}
                className="text-neutral-600 font-semibold rounded-b-none rounded-t-lg"
              />
              <div>
                <Button
                  onClick={onCancelEdit}
                  className="border-none shadow-none rounded-t-none rounded-bl-lg rounded-br-none h-[40px] w-1/2"
                >
                  Cancel
                </Button>
                <Button
                  disabled={
                    updatedComment.trim() === "" ||
                    updatedComment === comment.comment
                  }
                  type="primary"
                  onClick={onSave}
                  className="border-none shadow-none rounded-t-none rounded-bl-none  h-[40px] w-1/2"
                >
                  Save
                </Button>
              </div>
            </div>
          )}
          {!isDisabledToComment && (
            <>
              {isAssignment ? (
                currentUserId === comment.createdBy && (
                  <div className="border-b border-neutral-300 "></div>
                )
              ) : (
                <div className="border-b border-neutral-300 "></div>
              )}

              <div className="flex justify-end">
                <div className="flex items-center gap-5">
                  {!isAssignment && (
                    <div className="flex items-center gap-1">
                      <Tooltip
                        title={`${!isLiked ? "Like" : "Unlike"} comment`}
                      >
                        <div
                          onClick={() => handleLike(comment.id, isLiked)}
                          className="size-5 cursor-pointer"
                        >
                          <LikeIcon isLiked={isLiked} />
                        </div>
                      </Tooltip>
                      <div className="text-neutral-600 text-sm leading-4">
                        {comment?.likedBy?.length}
                      </div>
                    </div>
                  )}
                  {currentUserId === comment.createdBy && (
                    <Tooltip title="Edit comment">
                      <div
                        onClick={() => {
                          setEditingItem(comment);
                          setUpdatedComment(comment.comment);
                        }}
                      >
                        <EditOutlined className="text-primary-500 text-[15px] cursor-pointer" />
                      </div>
                    </Tooltip>
                  )}
                  {currentUserId === comment.createdBy && (
                    <Tooltip title="Delete comment">
                      <div
                        onClick={() => {
                          deleteComment(comment.id);
                        }}
                      >
                        <DeleteOutlined className="text-primary-500 text-[15px] cursor-pointer" />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </Card>
    </>
  );
};

export default DiscussionComment;

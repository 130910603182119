import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Card, Input, Skeleton } from "antd";

import EmptyState from "../../../shared/EmptyState";
import DiscussionCard from "../../../shared/Discussion/DisucussionCard";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import DiscussionQuestionCard from "../../../shared/Discussion/DiscussionQuestionCard";
import { DiscussionType } from "../../../../utils";
import { newsFeedType } from "../../../../utils/constants/newsFeedTypes";

const EducatorDiscussionScreen = (props: any) => {
  const {
    discussions,
    discussionsNewsFeed,
    loading,
    loaded,
    actions: { getDiscussions, getLearnerNewsFeed, deleteNewsFeed },
  } = props;

  const navigate = useNavigate();
  const [filteredDiscussions, setFilteredDiscussions] =
    useState<DiscussionType[]>(discussions);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All Discussions");
  const filterProps = [
    { key: "All Discussions", value: "All Discussions" },
    { key: "Resolved", value: "Resolved" },
    { key: "Unresolved", value: "Unresolved" },
  ];
  const allDiscussions = filterProps[0];
  const resolvedDiscussions = filterProps[1];
  const unresolvedDiscussions = filterProps[2];
  const [activeCard, setActiveCard] = useState<DiscussionType | null>(null);

  useEffect(() => {
    if (loaded) return;
    getDiscussions();
  }, []);

  useEffect(() => {
    getLearnerNewsFeed();
  }, []);

  useEffect(() => {
    if (discussionsNewsFeed && discussionsNewsFeed > 0) {
      deleteNewsFeed({ type: newsFeedType.discussion });
    }
  }, [discussionsNewsFeed]);

  useEffect(() => {
    if (discussions.length === 0 || window.innerWidth < 1200) return;
    document.body.classList.add("learner-discussion-layout");

    return () => {
      document.body.classList.remove("learner-discussion-layout");
    };
  }, [discussions]);

  useEffect(() => {
    let filtered = discussions;

    if (filter === "Resolved") {
      filtered = filtered.filter(
        (discussion: DiscussionType) => discussion.isResolved
      );
    } else if (filter === "Unresolved") {
      filtered = filtered.filter(
        (discussion: DiscussionType) => !discussion.isResolved
      );
    }

    if (searchTerm) {
      filtered = filtered.filter((discussion: DiscussionType) =>
        discussion.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredDiscussions(filtered);
  }, [discussions, searchTerm, filter]);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {discussions && discussions.length > 0 ? (
        <div className="xl:flex">
          <div className="xl:w-[42.4%] xl:pr-[80px]">
            <div className="sticky">
              <Input
                placeholder="Search"
                prefix={<SearchIcon className="mx-3" />}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="flex gap-5 my-5">
                <Button
                  type="link"
                  onClick={() => setFilter(allDiscussions.value)}
                  className={
                    filter === allDiscussions.value
                      ? "text-primary-600 font-semibold pl-0 pr-4 text-sm"
                      : "text-neutral-400 font-normal pl-0 pr-4 text-sm"
                  }
                >
                  {allDiscussions.key}
                </Button>
                <Button
                  type="link"
                  onClick={() => setFilter(resolvedDiscussions.value)}
                  className={
                    filter === resolvedDiscussions.value
                      ? "text-primary-600 font-semibold pl-0 pr-4 text-sm"
                      : "text-neutral-400 font-normal pl-0 pr-4 text-sm"
                  }
                >
                  {resolvedDiscussions.key}
                </Button>
                <Button
                  type="link"
                  onClick={() => setFilter(unresolvedDiscussions.value)}
                  className={
                    filter === unresolvedDiscussions.value
                      ? "text-primary-600 font-semibold pl-0 pr-4 text-sm"
                      : "text-neutral-400 font-normal pl-0 pr-4 text-sm"
                  }
                >
                  {unresolvedDiscussions.key}
                </Button>
              </div>
              <div>
                <div className="flex flex-col gap-4 pb-[60px] ">
                  {filteredDiscussions && filteredDiscussions.length > 0 ? (
                    filteredDiscussions.map(
                      (content: DiscussionType, index: number) => (
                        <Card
                          key={index}
                          onClick={() => setActiveCard(content)}
                          className={`p-[14px] duration-200 cursor-pointer border-none ${
                            content.id === activeCard?.id
                              ? "bg-primary-600"
                              : "shadow-md"
                          } `}
                        >
                          <DiscussionCard
                            content={content}
                            isActive={content.id === activeCard?.id}
                          />
                        </Card>
                      )
                    )
                  ) : (
                    <div className="flex items-center justify-center h-full pb-[60px]">
                      <EmptyState />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="xl:w-[57.6%] xl:pl-[80px] pb-[60px]">
            <div>
              {activeCard && <DiscussionQuestionCard activeCard={activeCard} />}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default EducatorDiscussionScreen;

import { Button, Col, Typography, Skeleton } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EntityCard from "../../../shared/EntityCard";
import AddEntity from "../../../shared/AddEntity";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import { ReactComponent as CourseIcon } from "../../../../assets/images/add-entity-course.svg";

const { Text } = Typography;

const AssignCoursesScreen = (props: any) => {
  const {
    courses,
    loading,
    actions: { setNextStep, assignCourse, getCourse },
  } = props;

  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [initialSelectedCourses, setInitialSelectedCourses] = useState<
    string[]
  >([]);
  const [showCourses, setShowCourses] = useState(false);
  const { id: moduleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isEdit = false, module = {} } = location.state || {};

  const toggleSelect = (courseId: string) => {
    setSelectedCourses((prevSelected: string[]) =>
      prevSelected.includes(courseId)
        ? prevSelected.filter((id: string) => id !== courseId)
        : [...prevSelected, courseId]
    );
  };

  useEffect(() => {
    setNextStep();
    getCourse();

    if (isEdit && module) {
      setShowCourses(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEdit && module && courses) {
      const initialCourses = module?.courses.map((course: any) => course.id);
      setSelectedCourses(initialCourses);
      setInitialSelectedCourses(initialCourses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  const handleAddSelectedCourses = () => {
    if (areCoursesSame()) {
      navigate(`/educator/module/${moduleId}`);
      return;
    }

    assignCourse({
      moduleId,
      courseIds: selectedCourses,
      navigate,
      isEdit: true,
    });
  };

  const areCoursesSame = () => {
    if (selectedCourses.length !== initialSelectedCourses.length) {
      return false;
    }
    const selectedSet = new Set(selectedCourses);
    const initialSet = new Set(initialSelectedCourses);
    let areSame = true;
    selectedSet.forEach((courseId) => {
      if (!initialSet.has(courseId)) {
        areSame = false;
      }
    });
    return areSame;
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {!showCourses ? (
        <div className="flex pb-12">
          <Col lg={8}>
            <CreateEntityInfo
              title=""
              text={`You created a module which will contain all created courses and lessons. You can continue to add the courses to the created Modul or add the courses at a later time.`}
            />
          </Col>
          <Col lg={16} className="max-w-[600px]">
            <Text className="text-neutral-600 font-semibold text-lg ml-2">
              Add Courses to Module
            </Text>
            <div className="h-full w-full flex items-center justify-center mt-2">
              <AddEntity
                firstButtonText="Select Existing Course/s"
                firstButtonOnClick={() => setShowCourses(true)}
                secondButtonText="Create New"
                secondButtonOnClick={() => navigate("/educator/course/create")}
                Icon={CourseIcon}
              />
            </div>
          </Col>
        </div>
      ) : (
        <div>
          <Text className="text-neutral-600 text-lg font-semibold">
            Select and Add Courses to Module or Unselect Selected if you no
            longer need it in this Module
          </Text>

          <div className="flex flex-wrap gap-4 mt-6 overflow-y-auto max-h-[calc(100vh-300px)]">
            {courses &&
              courses.map((course: any) => (
                <EntityCard
                  key={course.id}
                  title={course.title}
                  duration={course.duration || "0 hr 0 min"}
                  coverImageKey={course.coverImageKey || ""}
                  classes="w-[49%] max-w-[584px] h-[211px]"
                  footer={
                    <div>
                      {selectedCourses.includes(course.id) ? (
                        <Button
                          type="primary"
                          className="w-full h-8 bg-rose-400 hover:bg-rose-200"
                          onClick={() => toggleSelect(course.id)}
                          key="select"
                        >
                          Selected
                        </Button>
                      ) : (
                        <div className="flex gap-2">
                          <div className="bg-rose-400 text-white text-base font-semibold h-8 leading-8 rounded-lg px-4 ">
                            {course.points}
                            <span className="text-xs font-normal"> PTS</span>
                          </div>
                          <Button
                            type="primary"
                            className="w-full h-8 flex-1"
                            onClick={() => toggleSelect(course.id)}
                            key="select"
                          >
                            Select
                          </Button>
                        </div>
                      )}
                    </div>
                  }
                />
              ))}
          </div>

          <div className="flex justify-end mt-6">
            <Button
              className=" bg-primary-600 font-semibold text-base h-[50px] text-white"
              onClick={handleAddSelectedCourses}
            >
              Add Selected Courses
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AssignCoursesScreen;

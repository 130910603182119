import { useState, useEffect } from "react";
import { Typography, Button, Card, Input } from "antd";

import HeaderItems from "../../../../assets/images/header-items";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import SingleSubmissionListCard from "./SingleSubmissionListCard";

const { Text } = Typography;

const ViewSubmissions = ({
  submissions,
  activeSubmission,
  setActiveSubmission,
}: any) => {
  const [filteredSubmissions, setFilteredSubmission] =
    useState<any>(submissions);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("ALL");
  const filterProps = [
    { key: "ALL", value: "ALL" },
    { key: "IN PROGRESS", value: "IN PROGRESS" },
    { key: "GRADE", value: "GRADE" },
    { key: "FINISHED", value: "FINISHED" },
  ];
  const allSubmissions = filterProps[0];
  const inProgressSubmissions = filterProps[1];
  const gradedSubmissions = filterProps[2];
  const finishedSubmissions = filterProps[3];

  useEffect(() => {
    let filtered = submissions;

    if (filter === "FINISHED") {
      filtered = filtered.filter(
        (submission: any) => submission.status === "Submitted"
      );
    } else if (filter === "IN PROGRESS") {
      filtered = filtered.filter(
        (submission: any) => submission.status === "Not submitted"
      );
    } else if (filter === "GRADE") {
      filtered = filtered.filter((submission: any) => submission.grade);
    }

    if (searchTerm) {
      filtered = filtered.filter((submission: any) =>
        submission.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredSubmission(filtered);
  }, [submissions, searchTerm, filter]);

  return (
    <>
      <div className="sticky">
        <Input
          placeholder="Search"
          prefix={<SearchIcon className="mx-3" />}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="flex gap-5 my-5">
          <Button
            type="link"
            onClick={() => {
              setFilter(allSubmissions.value);
            }}
            className={
              filter === allSubmissions.value
                ? "text-primary-600 font-semibold px-0 text-sm"
                : "text-neutral-400 font-normal px-0 text-sm"
            }
          >
            {allSubmissions.key}
          </Button>
          <Button
            type="link"
            onClick={() => {
              setFilter(inProgressSubmissions.value);
            }}
            className={
              filter === inProgressSubmissions.value
                ? "text-primary-600 font-semibold px-0 text-sm"
                : "text-neutral-400 font-normal px-0 text-sm"
            }
          >
            {inProgressSubmissions.key}
          </Button>

          <Button
            type="link"
            onClick={() => {
              setFilter(gradedSubmissions.value);
            }}
            className={
              filter === gradedSubmissions.value
                ? "text-primary-600 font-semibold px-0 text-sm"
                : "text-neutral-400 font-normal px-0 text-sm"
            }
          >
            {gradedSubmissions.key}
          </Button>

          <Button
            type="link"
            onClick={() => {
              setFilter(finishedSubmissions.value);
            }}
            className={
              filter === finishedSubmissions.value
                ? "text-primary-600 font-semibold px-0 text-sm"
                : "text-neutral-400 font-normal px-0 text-sm"
            }
          >
            {finishedSubmissions.key}
          </Button>
        </div>

        <div>
          <div className="flex flex-col gap-4 pb-[60px overflow-y-scroll max-h-[calc(100vh-290px)]">
            {filteredSubmissions && filteredSubmissions.length > 0 ? (
              filteredSubmissions.map((submission: any, index: number) => (
                <SingleSubmissionListCard
                  submission={submission}
                  key={index}
                  setActiveSubmission={setActiveSubmission}
                />
              ))
            ) : (
              <Card className="shadow-md py-[60px]">
                <div className={`px-[40px]`}>
                  <div className="flex flex-col gap-10 items-center justify-center">
                    <div className="h-[60px]">
                      <HeaderItems.Assignment className="text-[80px] text-primary-600" />
                    </div>
                    <Text className="text-neutral-600 text-xl font-bold text-center">
                      There are currently no available Assignments
                    </Text>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSubmissions;

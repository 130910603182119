import { useEffect, useState } from "react";

const CircleProgress = ({
  size,
  percentage,
  firstStopColor,
}: {
  size: 140 | 100 | 70;
  percentage: any;
  firstStopColor?: string;
}) => {
  const [textSize, setTextSize] = useState("");

  useEffect(() => {
    switch (size) {
      case 70:
        return setTextSize("lg");
      case 100:
        return setTextSize("2xl");
      case 140:
        return setTextSize("2xl");
    }
  }, []);

  return (
    <div>
      <div className={`relative`}>
        <svg
          height={size}
          width={size}
          className="rotate-90"
          viewBox="0 0 36 36"
        >
          <defs>
            <linearGradient id="gradient">
              <stop offset="0%" stopColor={firstStopColor ?? "#FDA4AF"} />
              <stop offset="100%" stopColor="#5063EE" />
            </linearGradient>
          </defs>
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            className="stroke-current text-neutral-200"
            strokeWidth="1.5"
          ></circle>
          {percentage > 0 && (
            <circle
              cx="18"
              cy="18"
              r="16"
              fill="none"
              strokeWidth="4"
              strokeDasharray="100"
              strokeDashoffset={`${100 - (percentage / 100) * 100}`}
              strokeLinecap="round"
              stroke="url(#gradient)"
            ></circle>
          )}
        </svg>

        <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
          <div>
            <div
              className={`text-center text-${textSize} font-semibold text-neutral-500 flex justify-center`}
            >
              {percentage}
              <span className={`font-medium text-${textSize} `}>%</span>
            </div>
            {size === 140 && (
              <span className="text-neutral-500 text-[13px]">Completed</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircleProgress;

import React, { useEffect, useState } from "react";
import { Divider, Modal, Typography } from "antd";
import { DownloadOutlined, EyeFilled } from "@ant-design/icons";
import { ReactComponent as UploadPDFIcon } from "../../../assets/images/upload-pdf.svg";

const { Text } = Typography;
const cloudfrontKey = process.env.REACT_APP_CLOUDFRONT_KEY;

const AssignmentUploadedFiles = ({
  fileKey,
  presignedUrl,
  getPresignedUrl,
  removePresignedUrl,
}: {
  fileKey: string;
  presignedUrl: null | string;
  getPresignedUrl: ({ fileKey }: { fileKey: string }) => void;
  removePresignedUrl: () => void;
}) => {
  const handleDocumentDownload = () => {
    getPresignedUrl({ fileKey });
  };

  useEffect(() => {
    if (presignedUrl) {
      fetch(presignedUrl).then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const fileName = fileKey?.split("-")[fileKey?.split("-").length - 1];
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = fileName;
          alink.click();
        });
      });
      removePresignedUrl();
    }
  }, [presignedUrl]);

  return (
    <>
      <div className="w-full h-16 px-5 mt-2 rounded-lg bg-neutral-100 text-neutral-600 text-base flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <UploadPDFIcon
            style={{
              width: 30,
              height: 30,
            }}
          />
          <Text>{fileKey?.split("-")[fileKey?.split("-").length - 1]}</Text>
        </div>
        <div className="flex gap-2 items-center">
          <EyeFilled
            className="text-base text-primary-450 cursor-pointer"
            onClick={() =>
              window.open(`${cloudfrontKey + "/" + fileKey}`, "_blank")
            }
          />
          <Divider type="vertical" style={{ borderColor: "#BECBFE" }}></Divider>
          <div onClick={handleDocumentDownload}>
            <DownloadOutlined className="text-base text-primary-450 cursor-pointer" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignmentUploadedFiles;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Typography,
  Button,
  Form,
  Input,
  DatePicker,
  Upload,
  UploadFile,
  Card,
  Skeleton,
} from "antd";
import dayjs from "dayjs";

import { ReactComponent as AddIcon } from "../../../../assets/images/company-structure-items/add.svg";
import { ReactComponent as UploadPDFIcon } from "../../../../assets/images/upload-pdf.svg";
import AssignmentDiscussionCard from "../../../shared/Assignment/AssignmentDiscussionCard";
import DepartmentSelector from "../../../shared/DepartmentSelector";
import PositionSelector from "../../../shared/PositionSelector";
import UserSelector from "../../../shared/UserSelector";
import AssignmentUploadedFiles from "../../../shared/Assignment/AssignmentUploadedFiles";

const { Text } = Typography;
const { TextArea } = Input;

const AssignmentOverview = (props: any) => {
  const {
    assignment,
    presignedUrl,
    loading,
    actions: {
      getAssignmentById,
      editAssignment,
      getPresignedUrl,
      removePresignedUrl,
    },
  } = props;
  const id = useParams()?.id;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [status, setStatus] = useState<null | boolean>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [deadline, setDeadline] = useState<any>(null);
  const [pdfFileList, setPdfFileList] = useState<any>(null);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [showMoreDescription, setShowMoreDescription] = useState(false);
  const charMax = 225;
  const [isEditing, setIsEditing] = useState(false);
  const isSelected =
    selectedDepartments.length > 0 ||
    selectedPositions.length > 0 ||
    selectedUsers.length > 0;

  useEffect(() => {
    getAssignmentById({ id });
  }, [id]);

  useEffect(() => {
    if (!assignment) return;

    setStatus(assignment.isFinished);
    setTitle(assignment.title);
    setDescription(assignment.description);
    setStartDate(assignment.startDate);
    setDeadline(assignment.endDate);
    setPdfFileList(assignment.fileKey);

    setSelectedDepartments(assignment?.departmentIds.map((id: any) => id));
    setSelectedPositions(assignment?.positionIds.map((id: any) => id));
    setSelectedUsers(assignment?.userIds.map((id: any) => id));
  }, [assignment, isEditing]);

  useEffect(() => {
    isEditing && setIsEditing(false);
  }, [assignment]);

  const onFinish = () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("startDate", dayjs(startDate).format("YYYY-MM-DD"));
    formData.append("endDate", dayjs(deadline).format("YYYY-MM-DD"));
    if (pdfFileList.length > 0 && pdfFileList !== assignment.fileKey) {
      formData.append("file", pdfFileList[0].originFileObj as File);
    }
    selectedDepartments.forEach((departmentId: string) => {
      formData.append("departmentIds", departmentId);
    });

    selectedPositions.forEach((positionId: string) => {
      formData.append("positionIds", positionId);
    });

    selectedUsers.forEach((userId: string) => {
      formData.append("userIds", userId);
    });

    editAssignment({
      id: assignment.id,
      assignment: formData,
    });
  };

  const handlePdfChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setPdfFileList(fileList);
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);
  };

  const handleUserChange = (users: string[]) => {
    setSelectedUsers(users);
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      <div className="flex flex-col-reverse xl:flex-row pb-[60px]">
        <div className="xl:w-[40%] md:py-4 xl:p-0">
          <div className=" xl:max-w-[350px] 2xl:max-w-[410px]">
            <div className="hidden xl:flex flex-col flex-wrap gap-3 mb-3">
              <Text className="text-neutral-500  font-semibold text-lg">
                Assignment
              </Text>
              <Text className="text-neutral-500  text-base font-normal">
                Here is the Assignment Overview. Keep your Assignment current by
                editing or updating any necessary point.
              </Text>
              <Text className="text-neutral-500  text-base font-normal">
                Bellow you can manage the Discussion concerning this Assignment.
              </Text>
            </div>
            <div>
              <AssignmentDiscussionCard
                activeCard={assignment}
                isDisabledToComment={false}
                isAssignment={true}
              />
            </div>
          </div>
        </div>
        <div className="xl:w-[60%] xl:max-w-[535px]">
          <div className="flex flex-col xl:hidden flex-wrap gap-3 mb-3">
            <Text className="text-neutral-500  font-semibold text-lg">
              Assignment
            </Text>
            <Text className="text-neutral-500  text-base font-normal">
              Here is the Assignment Overview. Keep your Assignment current by
              editing or updating any necessary point.
            </Text>
            <Text className="text-neutral-500  text-base font-normal">
              Bellow you can manage the Discussion concerning this Assignment.
            </Text>
          </div>

          <Card
            className={`p-6 border-none ${
              !isEditing ? "bg-white shadow-sm " : "bg-neutral-100"
            }`}
          >
            <div className="flex flex-col gap-6">
              {!isEditing && (
                <div className="flex justify-end">
                  <Button
                    onClick={() => setIsEditing(true)}
                    type="link"
                    className="text-primary-600 font-semibold text-sm p-0"
                  >
                    Edit the Assignment
                  </Button>
                </div>
              )}

              <div>
                <div
                  className={`rounded-lg py-3 px-6 flex justify-between items-center ${
                    !isEditing ? "bg-neutral-100" : "bg-white"
                  }`}
                >
                  <Text className="text-neutral-700 text-base font-semibold">
                    Status
                  </Text>
                  <Text className="bg-rose-400 text-sm font-semibold text-white h-[32px] w-[220px] flex items-center justify-center rounded-lg">
                    {status ? "Finished" : "Unfinished"}
                  </Text>
                </div>
              </div>
              <div>
                {!isEditing ? (
                  <>
                    <Text className="text-neutral-600 text-sm font-semibold block">
                      Assignment Title
                    </Text>
                    <Text className="bg-neutral-100 rounded-lg mt-2 py-4 px-6 block text-neutral-600 text-base font-semibold">
                      {title}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text className="text-primary-600 text-sm font-semibold block">
                      Edit Assignment Title
                    </Text>
                    <Input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      className=" rounded-lg mt-2 px-6 text-neutral-600 text-base font-semibold"
                    />
                  </>
                )}
              </div>

              <div>
                {!isEditing ? (
                  <>
                    <Text className="text-neutral-600 text-sm font-semibold block">
                      Description
                    </Text>
                    <div className="bg-neutral-100 rounded-lg mt-2 py-4 px-6">
                      <Text
                        className={`text-neutral-600 text-base block overflow-hidden ${
                          !showMoreDescription ? "max-h-[144px]" : "h-auto"
                        } `}
                      >
                        {!showMoreDescription
                          ? `${description.substring(0, charMax)} ${
                              description.length > charMax ? ".." : ""
                            } `
                          : description}
                      </Text>
                      {description.length > charMax && (
                        <Text
                          onClick={() =>
                            setShowMoreDescription((prev) => !prev)
                          }
                          className="text-sm text-primary-600 font-semibold block mt-2 cursor-pointer w-fit"
                        >
                          {!showMoreDescription ? "More" : "Less"}
                        </Text>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <Text className="text-primary-600 text-sm font-semibold block">
                      Edit Description
                    </Text>
                    <TextArea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Add description here"
                      autoSize={{ minRows: 6, maxRows: 10 }}
                      className="rounded-lg mt-2 text-neutral-600 text-base font-semibold"
                    />
                  </>
                )}
              </div>

              <div className="flex gap-10">
                <div className="w-full">
                  {!isEditing ? (
                    <Text className="text-neutral-600 text-sm font-semibold">
                      Start Date
                    </Text>
                  ) : (
                    <Text className="text-primary-600 text-sm font-semibold">
                      Edit Start Date
                    </Text>
                  )}

                  <DatePicker
                    size="large"
                    className="w-full h-[50px] mt-2 px-6"
                    value={dayjs(startDate)}
                    onChange={(e) => {
                      setStartDate(e);
                      setDeadline(null);
                    }}
                    disabled={!isEditing}
                    allowClear={false}
                  />
                </div>
                <div className="w-full">
                  {!isEditing ? (
                    <Text className="text-neutral-600 text-sm font-semibold">
                      End Date
                    </Text>
                  ) : (
                    <Text className="text-primary-600 text-sm font-semibold">
                      Edit End Date
                    </Text>
                  )}

                  <DatePicker
                    size="large"
                    className="w-full h-[50px] mt-2 px-6"
                    placeholder="31 / 01 / 2024"
                    value={deadline && dayjs(deadline)}
                    onChange={(e) => setDeadline(e)}
                    disabledDate={(current) =>
                      !current || current.isBefore(dayjs())
                    }
                    minDate={startDate && dayjs(startDate)}
                    disabled={!isEditing}
                    allowClear={false}
                  />
                </div>
              </div>

              <div>
                {!isEditing ? (
                  <Text className="text-neutral-600 text-sm font-semibold block">
                    Assignment
                  </Text>
                ) : (
                  <Text className="text-primary-600 text-sm font-semibold block">
                    Edit Assignment
                  </Text>
                )}
                {!isEditing ? (
                  <AssignmentUploadedFiles
                    fileKey={pdfFileList}
                    presignedUrl={presignedUrl}
                    getPresignedUrl={getPresignedUrl}
                    removePresignedUrl={removePresignedUrl}
                  />
                ) : (
                  <div className="w-full min-h-[60px] px-6 mt-2 rounded-lg bg-white text-neutral-600 text-base flex items-center justify-between ">
                    <div className="flex gap-2 items-center">
                      <UploadPDFIcon
                        style={{
                          width: 30,
                          height: 30,
                        }}
                      />
                      <Text>
                        {typeof pdfFileList === "string"
                          ? pdfFileList?.split("-")[
                              pdfFileList?.split("-").length - 1
                            ]
                          : pdfFileList[0].name}
                      </Text>
                    </div>

                    <Upload
                      name="pdf"
                      maxCount={1}
                      beforeUpload={() => false}
                      accept="application/pdf"
                      onChange={handlePdfChange}
                      showUploadList={false}
                      iconRender={() => (
                        <div className="w-full h-full flex items-center justify-center">
                          <UploadPDFIcon />
                        </div>
                      )}
                    >
                      <div className="text-neutral-600 font-semibold cursor-pointer text-xs flex items-center mt-1 gap-2">
                        <AddIcon />
                        Upload New
                      </div>
                    </Upload>
                  </div>
                )}
              </div>

              <div className=" flex flex-col gap-3">
                <DepartmentSelector
                  onSelectionChange={handleDepartmentChange}
                  selectedDepartments={selectedDepartments}
                  setSelectedDepartments={setSelectedDepartments}
                  isDetailsState={!isEditing}
                  isAssignmentDetailsPage={true}
                />
                <PositionSelector
                  onSelectionChange={handlePositionChange}
                  selectedPositions={selectedPositions}
                  setSelectedPositions={setSelectedPositions}
                  isDetailsState={!isEditing}
                  isAssignmentDetailsPage={true}
                />
                <UserSelector
                  onSelectionChange={handleUserChange}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  isDetailsState={!isEditing}
                  isAssignmentDetailsPage={true}
                />
              </div>
            </div>
          </Card>

          {!isEditing ? (
            <div className="w-full flex justify-end mt-10">
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/educator/assignment/${assignment?.id}/grade`)
                }
                className="w-1/2 bg-primary-600 h-[50px]"
              >
                Grade Assignment
              </Button>
            </div>
          ) : (
            <div className="w-full flex justify-between gap-10 mt-10 px-6">
              <Button
                type="primary"
                onClick={() => setIsEditing(false)}
                className="w-1/2 bg-white border border-primary-200 text-primary-600 h-[50px]"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={onFinish}
                className="w-1/2 bg-primary-600 h-[50px]"
                disabled={
                  description.trim() === "" ||
                  title.trim() === "" ||
                  !startDate ||
                  !deadline ||
                  !pdfFileList ||
                  !isSelected
                }
              >
                Save Changes
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AssignmentOverview;

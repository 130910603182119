export const userTypes = {
  ADD_USER: "ADD_USER",
  GET_USER: "GET_USER",
  GET_USER_BY_ID: "GET_USER_BY_ID",
  DELETE_USER: "DELETE_USER",
  SET_USER: "SET_USER",
  SET_USER_BY_ID: "SET_USER_BY_ID",
  SET_DELETE_USER: "SET_DELETE_USER",
  GET_USER_BY_ROLE: "GET_USER_BY_ROLE",
  SET_USER_BY_ROLE: "SET_USER_BY_ROLE",
  EDIT_USER: "EDIT_USER",
  SIGN_UP: "SIGN_UP",
  LOGOUT_EDUCATOR: "LOGOUT_EDUCATOR",
};

export const addUser = (payload: any) => ({
  type: userTypes.ADD_USER,
  payload,
});

export const getUser = () => ({
  type: userTypes.GET_USER,
});

export const setUser = (payload: any) => ({
  type: userTypes.SET_USER,
  payload,
});

export const getUserById = (id: any) => ({
  type: userTypes.GET_USER_BY_ID,
  payload: id,
});

export const setUserById = (payload: any) => ({
  type: userTypes.SET_USER_BY_ID,
  payload,
});

export const deleteUser = (payload: any) => ({
  type: userTypes.DELETE_USER,
  payload,
});

export const setDeleteUser = (id: any) => ({
  type: userTypes.SET_DELETE_USER,
  id,
});

export const editUser = (payload: any) => ({
  type: userTypes.EDIT_USER,
  payload,
});

export const getUserByRole = (payload: any) => ({
  type: userTypes.GET_USER_BY_ROLE,
  payload,
});

export const signUp = (payload: any) => ({
  type: userTypes.SIGN_UP,
  payload,
});

export const logout = () => ({
  type: userTypes.LOGOUT_EDUCATOR,
});

import { createSelector } from "@reduxjs/toolkit";

import { EducatorAssignmentStateType } from "../../../utils";
import { StateType } from "../../../utils";

const selectAssignmentState = (state: StateType): EducatorAssignmentStateType =>
  state.educatorAssignmentReducer;

export const selectAssignments = createSelector(
  selectAssignmentState,
  (state: EducatorAssignmentStateType) => state.assignments
);

export const selectAssignment = createSelector(
  selectAssignmentState,
  (state: EducatorAssignmentStateType) => state.assignment
);

export const selectSubmissions = createSelector(
  selectAssignmentState,
  (state: EducatorAssignmentStateType) => state.submissions
);

export const selectUserSubmission = createSelector(
  selectAssignmentState,
  (state: EducatorAssignmentStateType) => state.submission
);

export const selectAssignmentComments = createSelector(
  selectAssignmentState,
  (state: EducatorAssignmentStateType) => state.assignmentComments
);

export const selectAssignmentLoading = createSelector(
  selectAssignmentState,
  (state: EducatorAssignmentStateType) => state.loading
);

export const selectAssignmentLoaded = createSelector(
  selectAssignmentState,
  (state: EducatorAssignmentStateType) => state.loaded
);

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Input, Popconfirm, Skeleton, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import EmptyState from "../../../shared/EmptyState";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import DiscussionCard from "../../../shared/Discussion/DisucussionCard";
import DiscussionQuestionCard from "../../../shared/Discussion/DiscussionQuestionCard";
import { DiscussionType } from "../../../../utils";
import { selectUser } from "../../../../redux/auth/selector";

const UserDiscussionScreen = (props: any) => {
  const {
    discussions,
    loading,
    loaded,
    actions: { getDiscussions, deleteDiscussionById },
  } = props;

  const [filteredDiscussions, setFilteredDiscussions] =
    useState<DiscussionType[]>(discussions);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("All Discussions");
  const filterProps = [
    { key: "All Discussions", value: "All Discussions" },
    { key: "Resolved", value: "Resolved" },
    { key: "Unresolved", value: "Unresolved" },
  ];
  const allDiscussions = filterProps[0];
  const resolvedDiscussions = filterProps[1];
  const unresolvedDiscussions = filterProps[2];
  const [activeCard, setActiveCard] = useState<DiscussionType | null>(null);
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
    if (loaded) return;
    getDiscussions();
  }, []);

  useEffect(() => {
    if (discussions.length === 0 || window.innerWidth < 1200) return;
    document.body.classList.add("learner-discussion-layout");

    return () => {
      document.body.classList.remove("learner-discussion-layout");
    };
  }, [discussions]);

  useEffect(() => {
    let filtered = discussions;

    if (filter === "Resolved") {
      filtered = filtered.filter(
        (discussion: DiscussionType) => discussion.isResolved
      );
    } else if (filter === "Unresolved") {
      filtered = filtered.filter(
        (discussion: DiscussionType) => !discussion.isResolved
      );
    }

    if (searchTerm) {
      filtered = filtered.filter((discussion: DiscussionType) =>
        discussion.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredDiscussions(filtered);
  }, [discussions, searchTerm, filter]);

  const deleteDiscussion = (id: string) => {
    deleteDiscussionById({ id });
    setActiveCard(null);
  };

  useEffect(() => {
    if (!activeCard) return;
    setActiveCard({ ...activeCard, isResolved: !activeCard.isResolved });
  }, [discussions]);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {discussions && discussions.length > 0 ? (
        <div className="xl:flex">
          <div className="xl:w-[42.4%] xl:pr-[80px]">
            <div className="sticky">
              <Input
                placeholder="Search"
                prefix={<SearchIcon className="mx-3" />}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="flex justify-between items-center">
                <div className="flex gap-5 my-5">
                  <Button
                    type="link"
                    onClick={() => setFilter(allDiscussions.value)}
                    className={
                      filter === allDiscussions.value
                        ? "text-primary-600 font-semibold pl-0 pr-4 text-sm"
                        : "text-neutral-400 font-normal pl-0 pr-4 text-sm"
                    }
                  >
                    {allDiscussions.key}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => setFilter(resolvedDiscussions.value)}
                    className={
                      filter === resolvedDiscussions.value
                        ? "text-primary-600 font-semibold pl-0 pr-4 text-sm"
                        : "text-neutral-400 font-normal pl-0 pr-4 text-sm"
                    }
                  >
                    {resolvedDiscussions.key}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => setFilter(unresolvedDiscussions.value)}
                    className={
                      filter === unresolvedDiscussions.value
                        ? "text-primary-600 font-semibold pl-0 pr-4 text-sm"
                        : "text-neutral-400 font-normal pl-0 pr-4 text-sm"
                    }
                  >
                    {unresolvedDiscussions.key}
                  </Button>
                </div>
                <div className="xl:hidden ">
                  {activeCard && activeCard.createdBy === user.id && (
                    <Tooltip title="Delete discussion">
                      <Popconfirm
                        title="Delete discussion"
                        description="Are you sure to delete this discussion?"
                        onConfirm={() => deleteDiscussion(activeCard.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button className="border-none shadow-none bg-transparent h-[50px]">
                          <DeleteOutlined className="text-3xl text-primary-600" />
                        </Button>
                      </Popconfirm>
                    </Tooltip>
                  )}
                  <Button
                    type="primary"
                    onClick={() => navigate("/user/discussion/create")}
                    className="h-[40px] bg-primary-600 text-white font-semibold rounded-lg"
                  >
                    Start New Discussion
                  </Button>
                </div>
              </div>

              <div>
                <div className="flex flex-col gap-4 pb-[60px]">
                  {filteredDiscussions && filteredDiscussions.length > 0 ? (
                    filteredDiscussions.map(
                      (content: DiscussionType, index: number) => (
                        <Card
                          key={index}
                          onClick={() => setActiveCard(content)}
                          className={`p-[14px] duration-200 cursor-pointer border-none ${
                            content.id === activeCard?.id
                              ? "bg-primary-600"
                              : "shadow-md"
                          } `}
                        >
                          <DiscussionCard
                            content={content}
                            isActive={content.id === activeCard?.id}
                          />
                        </Card>
                      )
                    )
                  ) : (
                    <div className="flex items-center justify-center h-full pb-[60px]">
                      <EmptyState />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:w-[57.6%] xl:pl-[80px] pb-[60px]">
            <div className="">
              <div className="hidden xl:flex justify-end items-center">
                {activeCard && activeCard.createdBy === user.id && (
                  <Tooltip title="Delete discussion">
                    <Popconfirm
                      title="Delete discussion"
                      description="Are you sure to delete this discussion?"
                      onConfirm={() => deleteDiscussion(activeCard.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button className="border-none shadow-none bg-transparent h-[50px]">
                        <DeleteOutlined className="text-4xl text-primary-600" />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                )}
                <Button
                  type="primary"
                  onClick={() => navigate("/user/discussion/create")}
                  className="w-1/2 h-[50px] bg-primary-600 text-white font-semibold rounded-lg ml-6"
                >
                  Start New Discussion
                </Button>
              </div>
              {activeCard && <DiscussionQuestionCard activeCard={activeCard} />}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default UserDiscussionScreen;

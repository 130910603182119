import { Card, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const QuizCard = (props: any) => {
  const { quizzie } = props;
  const navigate = useNavigate();

  return (
    <Card className="p-6 shadow-sm">
      <div className="h-full flex flex-col">
        <div className="flex-1">
          <Text className="text-neutral-600 font-semibold text-base">
            {quizzie.title}
          </Text>
        </div>
        <div className="flex items-center gap-2 my-4">
          <div
            className="h-[30px] w-[30px] bg-primary-600 text-white font-semibold text-sm rounded-3xl text-center leading-[30px]"
            style={{
              boxShadow: "inset 0 2px 3px 0 rgba(0, 0, 0, 0.25)",
            }}
          >
            {quizzie.questions.length}
          </div>
          <Text className="text-neutral-600 ">Quizzie Questions</Text>
        </div>
        <div className="border-t border-primary-200 pt-4 flex gap-4">
          <div className="bg-rose-400 rounded-lg min-w-[73px] text-center">
            <Text className="font-semibold text-sm text-white leading-[30px]">
              {quizzie.points} {""}
              <span className="text-[10px] font-normal">PTS</span>
            </Text>
          </div>
          {quizzie.isFinished ? (
            <div className="w-full flex items-center justify-center text-white text-base rounded-lg bg-success-550 font-semibold">
              Finished
            </div>
          ) : (
            <Button
              type="primary"
              className="flex-1 rounded-lg bg-primary-600 font-semibold"
              onClick={() => navigate(`/user/quizzie/${quizzie.id}`)}
            >
              Take a Quizzie
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default QuizCard;

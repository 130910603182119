import { Avatar, Card, Image, InputNumber, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import arrowRight from "../../../../assets/images/arrow-right-circle.svg";

const { Text } = Typography;

const GradeSubmission = ({
  activeSubmission,
  setActiveSubmission,
  grade,
  setGrade,
}: {
  activeSubmission: any;
  setActiveSubmission: any;
  grade: number | null;
  setGrade: React.Dispatch<React.SetStateAction<number | null>>;
}) => {
  return (
    <>
      <div className="h-[84px] flex items-center justify-between w-full">
        <div className="flex items-center gap-4 w-3/4">
          <div className="size-7 rotate-180 cursor-pointer">
            <Image
              preview={false}
              src={arrowRight}
              onClick={() => setActiveSubmission(null)}
            />
          </div>

          <div className="flex items-center gap-4 bg-white p-6 py-3 rounded-lg border-none shadow-sm w-[75%] max-h-[120px]">
            <div>
              <Avatar
                size={60}
                src={
                  activeSubmission?.profilePictureKey &&
                  process.env.REACT_APP_CLOUDFRONT_KEY +
                    "/" +
                    activeSubmission?.profilePictureKey
                }
                icon={<UserOutlined />}
              />
            </div>
            <div className="flex flex-col  w-full">
              <div className="text-success-550 font-medium text-sm">
                Student
              </div>
              <Text className="text-neutral-700 font-semibold text-base overflow-auto h-[80px]">
                {activeSubmission?.name ?? activeSubmission?.email}
              </Text>
            </div>
          </div>
        </div>
        <div className="w-[25%] flex flex-col gap-2 h-full">
          {activeSubmission.grade === null ? (
            <>
              <Text className="text-neutral-600 text-sm font-semibold">
                Grade
              </Text>
              <InputNumber
                value={grade}
                onChange={(e) => setGrade(e)}
                className="w-full h-full flex items-center rounded-lg border-none shadow-sm placeholder:text-xs"
                type="number"
                min={0}
                max={100}
                placeholder="Type grade here"
              />
            </>
          ) : (
            <Card className="h-full">
              <div className="h-full rounded-lg shadow-sm flex flex-col items-center justify-center">
                <Text className="text-neutral-600 text-sm font-semibold">
                  Grade
                </Text>

                <Text className="text-success-550 text-lg font-semibold">
                  {activeSubmission.grade}/100
                </Text>
              </div>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};

export default GradeSubmission;

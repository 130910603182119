import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Form,
  Input,
  DatePicker,
  Upload,
  UploadFile,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import { ReactComponent as UploadPDFIcon } from "../../../../assets/images/upload-pdf.svg";
import DepartmentSelector from "../../../shared/DepartmentSelector";
import PositionSelector from "../../../shared/PositionSelector";
import UserSelector from "../../../shared/UserSelector";

const { Text } = Typography;
const { TextArea } = Input;

const CreateAssignmetScreen = (props: any) => {
  const {
    actions: { createAssignment },
  } = props;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [deadline, setDeadline] = useState<any>(null);
  const [pdfFileList, setPdfFileList] = useState<any>(null);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const isSelected =
    selectedDepartments.length > 0 ||
    selectedPositions.length > 0 ||
    selectedUsers.length > 0;

  const onFinish = () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("startDate", startDate.format("YYYY-MM-DD"));
    formData.append("endDate", deadline.format("YYYY-MM-DD"));

    if (pdfFileList.length > 0) {
      formData.append("file", pdfFileList[0].originFileObj as File);
    }

    selectedDepartments.forEach((departmentId: string) => {
      formData.append("departmentIds", departmentId);
    });
    selectedPositions.forEach((positionId: string) => {
      formData.append("positionIds", positionId);
    });
    selectedUsers.forEach((userId: string) => {
      formData.append("userIds", userId);
    });

    createAssignment({
      assignment: formData,
      navigate,
    });
  };

  const handlePdfChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setPdfFileList(fileList);
  };

  const handleDepartmentChange = (departments: string[]) => {
    setSelectedDepartments(departments);
  };

  const handlePositionChange = (positions: string[]) => {
    setSelectedPositions(positions);
  };

  const handleUserChange = (users: string[]) => {
    setSelectedUsers(users);
  };

  return (
    <>
      <div className="flex">
        <div className="w-1/3 md:p-4 xl:p-0">
          <CreateEntityInfo
            title="New Assignment"
            text="Start a new assignment by adding all information and Continue the
              process."
          />
        </div>
        <div className="w-2/3 max-w-[600px]">
          <div className="h-full flex flex-col justify-between">
            <Form
              form={form}
              name="basic"
              onFinish={onFinish}
              autoComplete="off"
              size="large"
            >
              <Text className="text-neutral-600 text-sm font-semibold">
                Title
              </Text>
              <Form.Item
                name="title"
                rules={[{ required: true, message: "Please type title!" }]}
                className="mt-2 "
              >
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  autoComplete="off"
                  name="title"
                  placeholder="Type the title here"
                  className="shadow-md rounded-lg text-neutral-600 font-semibold text-base placeholder:font-normal placeholder:text-sm"
                />
              </Form.Item>

              <Text className="text-neutral-600 text-sm font-semibold">
                Description
              </Text>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please add description!",
                  },
                ]}
                className="mt-2 "
              >
                <TextArea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Add description here"
                  autoSize={{ minRows: 6, maxRows: 10 }}
                  className="shadow-md rounded-lg text-neutral-600"
                />
              </Form.Item>

              <div className="flex gap-10">
                <div className="w-full">
                  <Text className="text-neutral-600 text-sm font-semibold">
                    Start Date
                  </Text>
                  <Form.Item
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select start date!",
                      },
                    ]}
                    className="mt-2 "
                  >
                    <DatePicker
                      placeholder="07 / 01 / 2024"
                      size="large"
                      className="w-full h-[50px]"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e);
                        setDeadline(null);
                        form.setFieldsValue({ deadline: null });
                      }}
                      allowClear={false}
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <Text className="text-neutral-600 text-sm font-semibold">
                    Deadline
                  </Text>
                  <Form.Item
                    name="deadline"
                    rules={[
                      {
                        required: true,
                        message: "Please select deadline!",
                      },
                    ]}
                    className="mt-2 "
                  >
                    <DatePicker
                      placeholder="31 / 01 / 2024"
                      size="large"
                      className="w-full h-[50px]"
                      value={deadline}
                      onChange={(e) => setDeadline(e)}
                      disabledDate={(current) =>
                        !current || current.isBefore(dayjs())
                      }
                      allowClear={false}
                      minDate={startDate && dayjs(startDate)}
                    />
                  </Form.Item>
                </div>
              </div>

              <div>
                {!pdfFileList && (
                  <Text className="text-neutral-600 text-sm font-semibold">
                    Upload file
                  </Text>
                )}
                <Upload
                  name="pdf"
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={() => false}
                  accept="application/pdf"
                  className="bg-white rounded-lg mt-2"
                  fileList={pdfFileList}
                  onChange={handlePdfChange}
                  showUploadList={false}
                  iconRender={() => (
                    <div className="w-full h-full flex items-center justify-center">
                      <UploadPDFIcon />
                    </div>
                  )}
                >
                  {!pdfFileList && (
                    <div className="flex flex-col gap-1 items-center justify-center h-[200px]">
                      <UploadPDFIcon />
                      <p className="text-neutral-600 text-base font-medium mt-3">
                        Upload a PDF file/s
                      </p>
                      <p className="text-xs text-neutral-500 font-normal">
                        File size up to 5MB
                      </p>
                    </div>
                  )}
                </Upload>
                {pdfFileList && (
                  <>
                    <Text className="text-neutral-600 text-sm font-semibold">
                      PDF Document
                    </Text>
                    <div className="w-full h-20 px-5 mt-2 rounded-lg bg-white text-neutral-600 text-base flex items-center justify-between border border-primary-450 border-dashed">
                      <div className="flex gap-2 items-center">
                        <UploadPDFIcon
                          style={{
                            width: 30,
                            height: 30,
                          }}
                        />
                        <Text>{pdfFileList[0].name}</Text>
                      </div>
                      <div className="flex gap-2 items-center">
                        <CloseOutlined
                          onClick={() => setPdfFileList(null)}
                          className="text-base text-primary-450 cursor-pointer"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="mt-10 flex flex-col gap-2">
                <DepartmentSelector
                  onSelectionChange={handleDepartmentChange}
                  selectedDepartments={selectedDepartments}
                  setSelectedDepartments={setSelectedDepartments}
                />
                <PositionSelector
                  onSelectionChange={handlePositionChange}
                  selectedPositions={selectedPositions}
                  setSelectedPositions={setSelectedPositions}
                />
                <UserSelector
                  onSelectionChange={handleUserChange}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                />
              </div>

              <Form.Item>
                <div className="w-full flex justify-end gap-4 mt-5">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-1/2 bg-primary-600 h-[50px]"
                    disabled={
                      description.trim() === "" ||
                      title.trim() === "" ||
                      !startDate ||
                      !deadline ||
                      !pdfFileList ||
                      !isSelected
                    }
                  >
                    Create Assignment
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAssignmetScreen;

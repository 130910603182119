import { createSelector } from "reselect";
import { LearnerStatisticsState } from "../../../utils/types/Learner";

const selectLearnerStatisticState = (
  state: any
): { learnerStatistics: LearnerStatisticsState } =>
  state.learnerStatisticsReducer;

export const selectLearnerStatistics = createSelector(
  selectLearnerStatisticState,
  (state: { learnerStatistics: LearnerStatisticsState }) =>
    state.learnerStatistics
);

export const selectLearnerStatisticsLoading = createSelector(
  selectLearnerStatisticState,
  (state: any) => state.loading
);

export const selectLearnerStatisticsLoaded = createSelector(
  selectLearnerStatisticState,
  (state: any) => state.loaded
);

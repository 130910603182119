import { Image, Input } from "antd";
import messageIcon from "../../../assets/images/message.svg";

const { TextArea } = Input;

const CreateComment = ({
  showAddReplyInput,
  setShowAddReplyInput,
  newComment,
  setNewComment,
  handleCommentSubmit,
  isStarted,
  isAssignment,
}: {
  showAddReplyInput: boolean;
  setShowAddReplyInput: React.Dispatch<React.SetStateAction<boolean>>;
  newComment: string;
  setNewComment: React.Dispatch<React.SetStateAction<string>>;
  handleCommentSubmit: () => Promise<void>;
  isStarted?: boolean;
  isAssignment?: boolean;
}) => {
  return (
    <>
      <button
        onClick={() => setShowAddReplyInput(true)}
        className="flex gap-1 mt-4"
      >
        {!showAddReplyInput && (
          <>
            <div>
              <Image src={messageIcon} preview={false} />
            </div>
            <div className="text-neutral-600">
              {!isAssignment
                ? "Add a Reply"
                : isStarted
                ? "Add a Reply"
                : "Start a discussion"}
            </div>
          </>
        )}
      </button>

      {showAddReplyInput && (
        <div className="flex flex-col gap-4 p-4 rounded-lg border border-primary-300 bg-neutral-100">
          <div>
            <TextArea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              variant="borderless"
              autoSize
              className="py-1 px-0 bg-neutral-100 text-sm"
              placeholder="Type your comment/reply here"
            />
          </div>
          <div className="border-b border-primary-300"></div>
          <div className="flex justify-end gap-1 h-5">
            <button
              disabled={newComment.trim() === ""}
              onClick={handleCommentSubmit}
              className={`flex gap-1 ${
                newComment.trim() === "" && "cursor-not-allowed"
              }`}
            >
              <div>
                <Image src={messageIcon} preview={false} />
              </div>
              <div className="text-neutral-600">
                {!isAssignment
                  ? "Add a Reply"
                  : isStarted
                  ? "Add a Reply"
                  : "Start a discussion"}
              </div>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateComment;

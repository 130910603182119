export const assignmentTypes = {
  CREATE_ASSIGNMENT: "CREATE_ASSIGNMENT",
  GET_ASSIGNMENTS: "GET_ASSIGNMENTS",
  GET_ASSIGNMENT_BY_ID: "GET_ASSIGNMENT_BY_ID",
  GET_SUBMISSIONS: "GET_SUBMISSIONS",
  GET_USER_SUBMISSION: "GET_USER_SUBMISSION",
  DELETE_ASSIGNMENT: "DELETE_ASSIGNMENT",
  SET_ASSIGNMENTS: "SET_ASSIGNMENTS",
  SET_ASSIGNMENT_BY_ID: "SET_ASSIGNMENT_BY_ID",
  SET_SUBMISSIONS: "SET_SUBMISSIONS",
  SET_USER_SUBMISSION: "SET_USER_SUBMISSION",
  EDIT_ASSIGNMENT: "EDIT_ASSIGNMENT",
  USER_SUBMIT_FILES: "USER_SUBMIT_FILES",
  GRADE_SUBMISSION: "GRADE_SUBMISSION",
  CREATE_ASSIGNMENT_COMMENT: "CREATE_ASSIGNMENT_COMMENT",
  SET_ASSIGNMENT_COMMENTS: "SET_ASSIGNMENT_COMMENTS",
  UPDATE_ASSIGNMENT_COMMENTS: "UPDATE_ASSIGNMENT_COMMENTS",
  EDIT_ASSIGNMENT_COMMENT: "EDIT_ASSIGNMENT_COMMENT",
  SET_EDITED_ASSIGNMENT_COMMENT: "SET_EDITED_ASSIGNMENT_COMMENT",
  DELETE_ASSIGNMENT_COMMENT: "DELETE_ASSIGNMENT_COMMENT",
  SET_DELETED_ASSIGNMENT_COMMENT: "SET_DELETED_ASSIGNMENT_COMMENT",
};

export const createAssignment = (payload: any) => ({
  type: assignmentTypes.CREATE_ASSIGNMENT,
  payload,
});

export const getAssignments = () => ({
  type: assignmentTypes.GET_ASSIGNMENTS,
});

export const setAssignments = (payload: any) => ({
  type: assignmentTypes.SET_ASSIGNMENTS,
  payload,
});

export const getAssignmentById = (id: any) => ({
  type: assignmentTypes.GET_ASSIGNMENT_BY_ID,
  payload: id,
});

export const setAssignmentById = (payload: any) => ({
  type: assignmentTypes.SET_ASSIGNMENT_BY_ID,
  payload,
});

export const deleteAssignment = (payload: any) => ({
  type: assignmentTypes.DELETE_ASSIGNMENT,
  payload,
});

export const editAssignment = (payload: any) => ({
  type: assignmentTypes.EDIT_ASSIGNMENT,
  payload,
});

export const userSubmitFiles = (payload: any) => ({
  type: assignmentTypes.USER_SUBMIT_FILES,
  payload,
});

export const getSubmissions = (payload: any) => ({
  type: assignmentTypes.GET_SUBMISSIONS,
  payload,
});

export const setSubmissions = (payload: any) => ({
  type: assignmentTypes.SET_SUBMISSIONS,
  payload,
});

export const getUserSubmission = (payload: any) => ({
  type: assignmentTypes.GET_USER_SUBMISSION,
  payload,
});

export const setUserSubmission = (payload: any) => ({
  type: assignmentTypes.SET_USER_SUBMISSION,
  payload,
});

export const gradeSubmission = (payload: any) => ({
  type: assignmentTypes.GRADE_SUBMISSION,
  payload,
});

export const createAssignmentComment = (payload: any) => ({
  type: assignmentTypes.CREATE_ASSIGNMENT_COMMENT,
  payload,
});

export const setAssignmentComments = (payload: any) => ({
  type: assignmentTypes.SET_ASSIGNMENT_COMMENTS,
  payload,
});

export const updateAssignmentComments = (payload: any) => ({
  type: assignmentTypes.UPDATE_ASSIGNMENT_COMMENTS,
  payload,
});

export const editAssignmentComment = (payload: any) => ({
  type: assignmentTypes.EDIT_ASSIGNMENT_COMMENT,
  payload,
});

export const setEditedAssignmentComment = (payload: any) => ({
  type: assignmentTypes.SET_EDITED_ASSIGNMENT_COMMENT,
  payload,
});

export const deleteAssignmentComment = (payload: any) => ({
  type: assignmentTypes.DELETE_ASSIGNMENT_COMMENT,
  payload,
});

export const setDeletedAssignmentComment = (payload: any) => ({
  type: assignmentTypes.SET_DELETED_ASSIGNMENT_COMMENT,
  payload,
});

import { Action, bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";

import { StateType } from "../../../../utils/types";
import { createDiscussion } from "../../../../redux/learner/actions";

const mapStateToProps = (state: StateType) => ({});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      createDiscussion,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);

import { Typography, Button, Input, Skeleton } from "antd";
import { useEffect, useState } from "react";
import EntityCard from "../../../shared/EntityCard";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search.svg";
import EmptyState from "../../../shared/EmptyState";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const EducatorLessonsScreen = (props: any) => {
  const {
    lessons,
    loading,
    loaded,
    actions: { getLesson },
  } = props;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLessons, setFilteredLessons] = useState(lessons);
  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) return;
    getLesson();
  }, []);

  useEffect(() => {
    setFilteredLessons(
      lessons.filter((lesson: any) =>
        lesson.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, lessons]);

  if (loading) {
    return <Skeleton active />;
  }
  return (
    <>
      {lessons && lessons.length > 0 ? (
        <div>
          <div className="flex flex-row gap-9">
            <Input
              placeholder="Search"
              prefix={<SearchIcon className="mx-3" />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Button
              type="primary"
              className="w-64 h-[50px] text-base leading-[18px] font-semibold"
              onClick={() => navigate("/educator/lesson/create")}
            >
              Create New Lesson
            </Button>
          </div>

          <div className="flex flex-wrap gap-4 mt-4  overflow-y-scroll max-h-[calc(100vh-238px)]">
            {filteredLessons?.length > 0 ? (
              filteredLessons.map((content: any, index: any) => (
                <EntityCard
                  key={content.id}
                  title={content.title}
                  coverImageKey={content.coverImageKey || ""}
                  duration={content.duration || "0 hr 0 min"}
                  footer={
                    <div className="flex flex-row justify-between items-center gap-4">
                      <div className="w-full bg-rose-400 border-none text-white py-[7px] rounded-lg h-8 flex flex-row items-baseline gap-1 justify-center">
                        <Text className="text-white text-base leading-[18px] font-semibold">
                          {content.points}
                        </Text>
                        <Text className="text-white  text-[10px] leading-3">
                          PTS
                        </Text>
                      </div>
                      <Button
                        className="bg-primary-600 text-white border-none text-sm leading-4 font-semibold"
                        onClick={() =>
                          navigate("/educator/lesson/" + content.id)
                        }
                      >
                        View Lesson
                      </Button>
                    </div>
                  }
                />
              ))
            ) : (
              <div className="flex items-center w-full justify-center h-full py-[60px]">
                <EmptyState />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center h-full pb-[60px]">
          <EmptyState />
        </div>
      )}
    </>
  );
};

export default EducatorLessonsScreen;

import { Button, Typography } from "antd";
import { AppleOutlined, GoogleOutlined } from "@ant-design/icons";
import logo from '../../../assets/images/logo-large.svg';
const { Paragraph } = Typography;

const AppDownload = () => {
  return (
    <div className="w-full h-screen flex items-center">
      <div className="p-12 text-center w-full flex flex-col gap-8 ">
        <img src={logo} alt="auth-image" className="w-60 m-auto" />
        
        <Paragraph>Download our app to enjoy the full experience.</Paragraph>
        <div className="flex flex-col gap-3">
          <Button
            type="primary"
            icon={<AppleOutlined />}
            href="https://apps.apple.com/us/app/mindforge-lms/id6738063595"
            target="_blank"
            rel="noopener noreferrer"
            className="h-12"
          >
            Download on the App Store
          </Button>
          <Button
            type="primary"
            icon={<GoogleOutlined />}
            href="https://play.google.com/store/apps/details?id=com.tfl.MindForge"
            target="_blank"
            rel="noopener noreferrer"
            className="h-12"
          >
            Get it on Google Play
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppDownload;
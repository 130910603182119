import React from "react";
import { Avatar, Button, Card, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SingleSubmissionListCard = ({ submission, setActiveSubmission }: any) => {
  return (
    <Card className={`p-6 py-3 rounded-lg border-none shadow-sm`}>
      <div className="flex items-center gap-4">
        <div>
          <Avatar
            size={60}
            src={
              submission?.profilePictureKey &&
              process.env.REACT_APP_CLOUDFRONT_KEY +
                "/" +
                submission?.profilePictureKey
            }
            icon={<UserOutlined />}
          />
        </div>
        <div className="flex flex-col  w-full">
          <Text className="text-neutral-700 font-semibold text-base line-clamp-2">
            {submission.name ?? submission.email}
          </Text>

          {submission.grade !== null ? (
            <div className="flex justify-between items-center w-full">
              <div className="text-success-550 font-medium text-sm">
                Finished - {submission.grade}
              </div>
              <Button
                onClick={() => setActiveSubmission(submission)}
                type="link"
                className="p-0 h-fit text-sm text-primary-600 bg-transparent border-none"
              >
                View
              </Button>
            </div>
          ) : submission.status === "Submitted" ? (
            <div className="flex justify-between items-center w-full">
              <div className="text-primary-600 font-medium text-sm">
                Submitted
              </div>
              <Button
                onClick={() => setActiveSubmission(submission)}
                type="link"
                className="p-0 h-fit text-sm text-primary-600 bg-transparent border-none"
              >
                Grade
              </Button>
            </div>
          ) : (
            <div className="text-neutral-400 font-medium text-sm">
              Not submitted
            </div>
          )}

          <div
            className={`mt-2 h-2 w-full ${
              submission.grade
                ? "bg-success-550"
                : submission.status === "Submitted"
                ? "bg-primary-500"
                : "bg-neutral-200"
            } rounded-md`}
          ></div>
        </div>
      </div>
    </Card>
  );
};

export default SingleSubmissionListCard;

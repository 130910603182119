import React, { useState } from "react";
import { useDispatch } from "react-redux";

import DiscussionComment from "./DiscussionSingleComment";
import { DiscussionCommentType } from "../../../utils";
import { discussionTypes } from "../../../redux/learner/actions";
import { assignmentTypes } from "../../../redux/educator/actions";

const DiscussionComments = ({
  comments,
  currentUserId,
  isDisabledToComment,
  isAssignment,
}: {
  comments: DiscussionCommentType[];
  currentUserId: string;
  isDisabledToComment?: boolean;
  isAssignment: boolean;
}) => {
  const dispatch = useDispatch();
  const [editingItem, setEditingItem] = useState<any>(null);
  const [updatedComment, setUpdatedComment] = useState("");

  const onSaveEdit = () => {
    dispatch({
      type: isAssignment
        ? assignmentTypes.EDIT_ASSIGNMENT_COMMENT
        : discussionTypes.EDIT_COMMENT,
      payload: {
        id: editingItem.id,
        comment: updatedComment,
      },
    });

    setEditingItem(null);
    setUpdatedComment("");
  };

  const handleCancelEdit = () => {
    setEditingItem(null);
  };

  const handleDeleteComment = (id: string) => {
    dispatch({
      type: isAssignment
        ? assignmentTypes.DELETE_ASSIGNMENT_COMMENT
        : discussionTypes.DELETE_COMMENT,
      payload: {
        id,
      },
    });
  };

  const handleLikeComment = (id: string, isLiked: boolean | undefined) => {
    dispatch({
      type: discussionTypes.LIKE_COMMENT,
      payload: {
        id,
        isLiked: !isLiked,
      },
    });
  };

  return (
    <>
      {comments &&
        comments.length > 0 &&
        comments.map((comment: DiscussionCommentType, index: number) => (
          <DiscussionComment
            key={index}
            comment={comment}
            currentUserId={currentUserId}
            isEditing={editingItem && editingItem.id === comment.id}
            setEditingItem={setEditingItem}
            updatedComment={updatedComment}
            setUpdatedComment={setUpdatedComment}
            onSave={onSaveEdit}
            onCancelEdit={handleCancelEdit}
            deleteComment={handleDeleteComment}
            handleLike={handleLikeComment}
            isDisabledToComment={isDisabledToComment}
            isAssignment={isAssignment}
          />
        ))}
    </>
  );
};

export default DiscussionComments;

import { useEffect, useState } from "react";
import { Col, Row, Form, Modal, Button, Skeleton, Tooltip } from "antd";

import { useNavigate, useParams } from "react-router-dom";
import CreateEntityInfo from "../../../shared/CreateEntityInfo";
import QuizForm from "../Create/Form/QuizForm";
import QuizOptions from "../Create/Options/QuizOptions";
import AiButton from "../../../shared/Ai/AiButton";

const QuizDetails = (props: any) => {
  const {
    actions: {
      setInitialSteps,
      setNextStep,
      setPreviousStep,
      getQuizById,
      editQuiz,
      generateQuiz,
    },
    quiz,
    loading,
    generateLoading,
  } = props;

  const [formValues, setFormValues] = useState({});
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [quizStep, setQuizStep] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deletedImages, setDeletedImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [generateButtonDisabled, setGeneratedButtonDisabled] = useState(true);
  const [generateButtonTooltip, setGenerateButtonTooltip] = useState<
    string | null
  >(null);

  useEffect(() => {
    setInitialSteps(2);
  }, []);

  useEffect(() => {
    getQuizById({ id });
  }, []);

  useEffect(() => {
    if (quiz) {
      form.setFieldsValue(quiz);
      setFormValues(quiz);

      const images = quiz.questions.reduce(
        (arr: any[], question: any, index: number) => {
          if (question.imageKey) {
            arr.push({
              questionIndex: index,
              imageKey: question.imageKey,
            });
          }
          return arr;
        },
        []
      );
      setExistingImages(images);
      checkVideoStatus();
    }
  }, [quiz]);

  const checkVideoStatus = () => {
    if (!quiz.lessonId) {
      setGeneratedButtonDisabled(true);
      setGenerateButtonTooltip("Lesson not assigned to quiz.");
    } else if (!quiz.lesson.videoStatus) {
      setGeneratedButtonDisabled(true);
      setGenerateButtonTooltip("Lesson does not have a video.");
    } else if (quiz.lesson.videoStatus === "AVAILABLE_WITHOUT_TRANSCRIPTION") {
      setGeneratedButtonDisabled(true);
      setGenerateButtonTooltip("Video does not support AI quiz.");
    } else if (quiz.lesson.videoStatus === "AVAILABLE") {
      setGeneratedButtonDisabled(false);
      setGenerateButtonTooltip(null);
    } else if (
      quiz.lesson.videoStatus === "NOT_PROCESSED" ||
      quiz.lesson.videoStatus === "PROCESSING"
    ) {
      setGeneratedButtonDisabled(true);
      setGenerateButtonTooltip("Video is processing.");
    } else {
      setGeneratedButtonDisabled(true);
      setGenerateButtonTooltip("Cannot generate AI quiz at this time.");
    }
  };

  const onFinishEditQuiz = () => {
    editQuiz({ quiz: formValues, id, navigate, deletedImages, existingImages });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (overwrite: boolean) => {
    setIsModalVisible(false);
    generateQuiz({ overwrite, lessonId: quiz.lessonId });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <>
      {quiz && (
        <Form
          form={form}
          name="quizForm"
          autoComplete="off"
          size="small"
          className="h-full"
          initialValues={quiz}
          onValuesChange={() => setFormValues(form.getFieldsValue())}
          onFinish={onFinishEditQuiz}
        >
          {quizStep === 1 ? (
            <Row>
              <Col lg={8}>
                <CreateEntityInfo
                  title="Test your students"
                  text="Create a Quiz by adding the questions and related answers. Once you finalize all questions proceed to Finish Quiz."
                ></CreateEntityInfo>
              </Col>
              <Col lg={16}>
                <QuizForm
                  form={form}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  setNextStep={setNextStep}
                  setQuizStep={setQuizStep}
                  isQuizzie={false}
                  isEdit={true}
                  quiz={quiz}
                  setDeletedImages={setDeletedImages}
                  generateQuizButton={
                    <Tooltip title={generateButtonTooltip}>
                      <div className="w-full flex justify-center mt-5 text-teal-300">
                        <AiButton
                          disabled={generateButtonDisabled}
                          handleOnClick={showModal}
                          classes="w-full"
                          content={
                            <>
                              {generateLoading ? (
                                <div className="ai-dots-loader"></div>
                              ) : (
                                "Generate AI Quiz"
                              )}
                            </>
                          }
                        />
                      </div>
                    </Tooltip>
                  }
                ></QuizForm>
              </Col>
            </Row>
          ) : (
            <QuizOptions
              form={form}
              formValues={formValues}
              setFormValues={setFormValues}
              setQuizStep={setQuizStep}
              setPreviousStep={setPreviousStep}
              isEdit={true}
            ></QuizOptions>
          )}
        </Form>
      )}
      <Modal
        title="Generate AI Quiz"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <div className="flex gap-4">
            <Button
              key="overwrite"
              type="primary"
              className="flex-1"
              onClick={() => handleOk(true)}
            >
              Overwrite
            </Button>
            <Button
              key="add"
              className="flex-1"
              type="primary"
              onClick={() => handleOk(false)}
            >
              Add to Existing
            </Button>
          </div>,
        ]}
        width={420}
      >
        <p>Do you want to overwrite the existing quiz or add to it?</p>
      </Modal>
    </>
  );
};

export default QuizDetails;

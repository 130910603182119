import { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";

const VideoPlayer = ({ videoUri }: { videoUri: string }) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const [videoId, setVideoId] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (videoUri) {
      const id = parseInt(videoUri.split("/").pop() || "");
      if (!isNaN(id)) {
        setVideoId(id);
        setError(null);
      } else {
        setVideoId(null);
        setError("Invalid video URI");
      }
    } else {
      setVideoId(null);
      setError("Video URI is null");
    }
  }, [videoUri]);

  useEffect(() => {
    if (playerRef.current && videoId !== null) {
      const player = new Player(playerRef.current, {
        id: videoId,
        responsive: true,
      });

      player.on('error', (error) => {
        setError("Video not found");
      });

      return () => {
        player.unload();
      };
    }
  }, [videoId]);

  return (
    <>
      {error ? (
        <div className="flex items-center justify-center h-full text-white">
          {error}
        </div>
      ) : (
        <div ref={playerRef} className="w-full h-full"></div>
      )}
    </>
  );
};

export default VideoPlayer;